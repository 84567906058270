import { useWeb3React } from "@web3-react/core";
import { getAuth } from "firebase/auth";
import { useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Route, RouteProps } from "react-router-dom";
import LoadingSpinner from "./components/layout/LoadingSpinner";
import Login from "./components/Login";
import { MetamaskProviderContext } from "./components/wallet/MetamaskProvider";

type ProtectedRouteProps = {
  path: string;
};

const ProtectedRoute = ({
  path,
  component: Component,
}: { component: React.FunctionComponent<any> } & ProtectedRouteProps & RouteProps): JSX.Element => {
  const { account, active } = useWeb3React();
  const { loading: loadingMetamask } = useContext(MetamaskProviderContext);

  const auth = getAuth();
  const [authUser, loadingUser] = useAuthState(auth);

  if (loadingMetamask || loadingUser) {
    return (
      <div className="w-full flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!(account && active)) {
    return <Login />;
  }

  if (!authUser) {
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route path={path} render={(props) => <Component {...props} />} />;
};

export default ProtectedRoute;
