import MainContainer from "./layout/MainContainer";
import ConnectWalletButton from "./wallet/ConnectWallet";

const Login = (): JSX.Element => (
  <MainContainer>
    <h2 className="text-xl font-bold my-2">Connect your wallet</h2>
    <span className="mb-2">To use the banity app you must connect Metamask</span>
    <ConnectWalletButton>
      <div className="flex items-center justify-between font-bold">
        <span>Connect with Metamask</span>
        <img src={`${process.env.PUBLIC_URL}/metamask32.png`} alt="metamask logo" />
      </div>
    </ConnectWalletButton>
  </MainContainer>
);

export default Login;
