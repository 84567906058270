import { ethers } from "ethers";
import { getAuth, signInWithCustomToken } from "firebase/auth";

const AUTH_ENDPOINT =
  process.env.REACT_APP_AUTH_ENDPOINT || "http://localhost:5001/ce-ban-banity/europe-west1/auth";

type GetNonceResponse = {
  nonce: string;
};

async function getNonce(address: string): Promise<string> {
  const fetchUrl = `${AUTH_ENDPOINT}/getNonce`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ address }),
  };
  const response = await fetch(fetchUrl, requestOptions);
  if (response.status !== 200) {
    console.error(JSON.stringify(response));
    return null;
  }

  return ((await response.json()) as GetNonceResponse).nonce;
}

type VerifyResponse = {
  token: string;
};

async function verify(address: string, signature: string): Promise<string> {
  const fetchUrl = `${AUTH_ENDPOINT}/verify`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ address, signature }),
  };
  const response = await fetch(fetchUrl, requestOptions);
  if (response.status !== 200) {
    console.error(JSON.stringify(response));
    return null;
  }

  return ((await response.json()) as VerifyResponse).token;
}

async function signInWithMetamask(signer: ethers.Signer): Promise<string> {
  const account = await signer.getAddress();
  const nonce = await getNonce(account);
  if (!nonce) {
    throw new Error("Cannot fetch nonce.");
  }

  const signature = await signer.signMessage(nonce);

  if (!signature) {
    throw new Error("Could not get signature.");
  }

  const token = await verify(account, signature);
  if (!token) {
    throw new Error("Error on verification.");
  }

  const userCredentials = await signInWithCustomToken(getAuth(), token);
  return userCredentials.user.getIdToken();
}

export default signInWithMetamask;
