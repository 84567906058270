import { ethers } from "ethers";
import * as Yup from "yup";
import { validate } from "bitcoin-address-validation";
import { Trait } from "../../../types/BanityNft";
import MintOption from "../../../types/MintOption";

export const MAX_FILE_SIZE_IN_BYTES = 10000000; // in byte, corresponds to file size of 10Mb
export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const validateFileSize = (file: File) => {
  if (!file) return true;

  return file.size <= MAX_FILE_SIZE_IN_BYTES;
};

const validateFileType = (file: File) => {
  if (!file) return true;

  return SUPPORTED_FORMATS.includes(file.type);
};

const validateEthereumAddress = (address: string) => {
  const VALID_ETH_ADDRES: boolean = ethers.utils.isAddress(address);
  return VALID_ETH_ADDRES;
};

const validateBitcoinAddress = (address: string) => {
  const VALID_BTC_ADDRESS: boolean = validate(address);
  return VALID_BTC_ADDRESS;
};

const MintTokenFormValidation = (mintOption: MintOption): Yup.AnyObjectSchema =>
  Yup.object().shape({
    tokenId: Yup.number()
      .typeError("Please enter a uint256 token ID")
      .min(1, "Token ID too small, it must be a uint256")
      .max(2 ** 256, "Token ID too large, it must be a uint256")
      .required("Please enter a token ID"),

    publicAddress: Yup.mixed()
      .test(
        "VALID_BTC_ADDRESS",
        "The provided address is no valid Bitcoin address.",
        (value: string) => validateBitcoinAddress(value),
      )
      .required("Please enter the public address you want to mint as a token."),

    trait: Yup.string()
      .required("Please specify the trait of the token.")
      .oneOf(
        Object.values(Trait),
        "Trait must be a valid banity trait: Silver, Gold, Black, Red, Blue",
      ),

    ownerAddress:
      mintOption === MintOption.MINT
        ? Yup.mixed()
            .test(
              "VALID_ETH_ADDRESS",
              "The provided address is no valid Ethereum address.",
              (value: string) => validateEthereumAddress(value),
            )
            .required("Please enter the address of the token's owner.")
        : Yup.string(),

    image: Yup.mixed()
      .test(
        "FILE_SIZE",
        "The provided image is too large. The image size is limited to 10Mb.",
        (value: File) => validateFileSize(value),
      )
      .test(
        "SUPPORTED_FILE_FORMAT",
        "The provided file format is not supported. Please just upload png or jpg files.",
        (value: File) => validateFileType(value),
      )
      .required("Please upload an image for the token."),
  });

export default MintTokenFormValidation;
