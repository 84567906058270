import LoadingSpinner from "../layout/LoadingSpinner";
import ModalContainer from "../layout/ModalContainer";

type LoadingModalProps = {
  loadingMessage: string;
};

const LoadingModal = ({ loadingMessage }: LoadingModalProps): JSX.Element => (
  <ModalContainer>
    <div className="px-40">
      <LoadingSpinner />
    </div>
    <div className="pt-10 px-10 break-words">{loadingMessage}</div>
  </ModalContainer>
);

export default LoadingModal;
