import {
  MintTokenFormFields,
  MintTokenFormInitialValues,
  MintTokenFormValues,
} from "../../../types/MintTokenForm";

export const mintTokenFormFields: MintTokenFormFields = {
  tokenId: {
    name: "tokenId",
    label: "Token Id",
    initialValue: "",
    placeholder: "1234...",
  },
  name: {
    name: "name",
    label: "Name",
    initialValue: "",
    placeholder: "BaNiTy",
  },
  publicAddress: {
    name: "publicAddress",
    label: "Public Bitcoin address",
    initialValue: "",
    placeholder: "1...",
  },
  trait: {
    name: "trait",
    label: "Trait",
    initialValue: "Silver",
    placeholder: "",
  },
  ownerAddress: {
    name: "ownerAddress",
    label: "Owner address",
    initialValue: "",
    placeholder: "0x...",
  },
  image: {
    name: "image",
    label: "Image",
    initialValue: "",
    placeholder: "",
  },
};

export const getMintTokenInitialValues = (): MintTokenFormInitialValues => {
  const initialValues = {};

  Object.values(mintTokenFormFields).forEach((formField) => {
    initialValues[formField.name] = formField.initialValue;
  });

  return initialValues as MintTokenFormValues;
};
