/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable import/prefer-default-export */

/* Event snippet for Purchase - Mint conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. */
export function gtagReportConversion() {
  window.gtag("event", "conversion", {
    send_to: "AW-303707434/KythCLywuYsDEKrq6JAB",
    transaction_id: "",
  });
}
