import { useEffect, useState } from "react";

import { getFirestore, collection, query, where } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import convertNumberToRedeemStage from "../utils/convertNumberToRedeemStage";
import { RedeemStage } from "../types/FirestoreToken";

type OwnedToken = { tokenId: number; redeemStage: RedeemStage };
type OwnedTokens = Array<OwnedToken>;
type OwnedBurnedNftsResult = [OwnedTokens | undefined, boolean, Error | undefined];

export default (ownerAddress: string | null | undefined): OwnedBurnedNftsResult => {
  const [ownedTokens, setOwnedTokens] = useState<OwnedTokens>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();

  const tokensCollection = collection(getFirestore(), "tokens");
  const tokenQuery = query(
    tokensCollection,
    where("isBurned", "==", true),
    where("owner", "==", ownerAddress),
  );
  const [tokens, loadingFirestore, errorFirestore] = useCollection(tokenQuery);

  useEffect(() => {
    setLoading(true);
    const fetchTokenIds = () => {
      if (!ownerAddress) {
        setLoading(false);
        return;
      }
      if (errorFirestore) {
        setError(errorFirestore);
        setLoading(false);
        return;
      }
      if (!loadingFirestore) {
        const tokenData = tokens.docs.map((doc) => ({
          tokenId: Number(doc.id),
          redeemStage: convertNumberToRedeemStage(doc.data().redeemStage as number),
        }));
        const tokenDataToDisplay = tokenData.filter(
          (token) => token.redeemStage !== RedeemStage.FINISHED,
        );
        setOwnedTokens(tokenDataToDisplay);
        setLoading(false);
      }
    };
    setLoading(false);
    void fetchTokenIds();
  }, [ownerAddress, tokens, loadingFirestore, errorFirestore]);

  return [ownedTokens, loading, error];
};
