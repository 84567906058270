import { useState } from "react";
import { ErrorData } from "@firebase/util";

import { useWeb3React } from "@web3-react/core";
import { providers } from "ethers";

import { doc, getFirestore } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { RedeemStage } from "../../../types/FirestoreToken";
import DeleteBtcKeyBtn from "./DeleteBtcKeyBtn";
import RedeemBtcKeyBtn from "./RedeemBtcKeyBtn";
import BtcKey from "./BtcKey";
import ConfirmDeleteBtcKeyModal from "./ConfirmDeleteBtcKeyModal";
import PrimaryButton from "../../buttons/PrimaryButton";
import checked from "../../../static/images/checked.svg";
import UserEmailForm from "./form/UserEmailForm";
import BurnDate from "./BurnDate";

import verifySignature from "../../../encrypt/verifySignature";
import decryptData from "../../../encrypt/decryptData";
import { useModal } from "../../modals/ModalContext";

type RedeemCardBodyProps = {
  redeemStage: RedeemStage;
  onClickBurn: () => void;
  tokenId: number;
};

const RedeemCardBody = ({
  redeemStage,
  onClickBurn,
  tokenId,
}: RedeemCardBodyProps): JSX.Element => {
  const [btcKey, setBtcKey] = useState<string>();
  const [redeemBtcKeyBtnVisible, setRedeemBtcKeyBtnVisible] = useState(true);
  const deleteBtcKeyBtnVisible = !redeemBtcKeyBtnVisible;
  const [confirmDeleteBtcKeyModalVisible, setConfirmDeleteBtcKeyModalVisible] = useState(false);

  const { library } = useWeb3React<providers.Web3Provider>();
  const signer = library.getSigner();
  const tokenDocReference = doc(getFirestore(), "tokens", tokenId.toString());
  const [token, loading, error] = useDocumentDataOnce(tokenDocReference);

  const [errorMessage, setErrorMessage] = useState<string>();

  const { setLoadingModal } = useModal();

  const onClickRedeemBtcKey = async () => {
    try {
      setLoadingModal("Redeeming Token: Waiting for confirmation in Metamask");
      const account = await signer.getAddress();
      const adminPublicAddress = token.adminPublicAddress as string;
      const encryptionData = token.encryptionData as string;

      const signature = token.signature as string;
      try {
        const verified = verifySignature({ adminPublicAddress, encryptionData, signature });
        if (verified) {
          const btcPrivateKey = await decryptData(account, encryptionData);
          setBtcKey(btcPrivateKey);
          setRedeemBtcKeyBtnVisible(false);
        }
      } catch (e) {
        const err = e as Error;
        const errorData = e as ErrorData;
        if (errorData.code === "INVALID_ARGUMENT") {
          setErrorMessage("Signature is missing. Please contact admin.");
        } else {
          setErrorMessage(err.message);
        }
      }
    } finally {
      setLoadingModal(null);
    }
  };

  const onClickDeleteBtcKey = () => {
    setConfirmDeleteBtcKeyModalVisible(true);
  };

  switch (redeemStage) {
    case RedeemStage.BURNED:
      return (
        <>
          <div className="px-20 py-5">
            <BurnDate tokenId={tokenId} />
          </div>
          <div className="text-center leading-loose p-2 text-lg ">
            <div>Wait for approval by Banity...</div>
            <div className="font-semibold pt-2">You can close this page and come back later.</div>
          </div>
          <UserEmailForm />
        </>
      );
    case RedeemStage.PRIVATE_KEY_INSERTED:
      return (
        <>
          {confirmDeleteBtcKeyModalVisible && (
            <ConfirmDeleteBtcKeyModal
              setIsVisible={setConfirmDeleteBtcKeyModalVisible}
              tokenId={tokenId}
            />
          )}
          <div className="w-full pt-5">
            <BtcKey btcKey={btcKey} />
            {!loading && !error ? (
              <>{redeemBtcKeyBtnVisible && <RedeemBtcKeyBtn onClick={onClickRedeemBtcKey} />} </>
            ) : (
              <span>Loading...</span>
            )}
            {deleteBtcKeyBtnVisible && <DeleteBtcKeyBtn onClick={onClickDeleteBtcKey} />}
            {errorMessage && <p className="pt-10">{errorMessage}</p>}
          </div>
        </>
      );
    case RedeemStage.REDEEMED:
      return (
        <>
          <div className="flex justify-center w-full">
            <img className="w-24" src={checked} alt="redeem icon" />
          </div>
          <div className="flex w-full justify-center pt-5 font-bold">
            You have successfully redeemed your private key!
          </div>
        </>
      );
    default:
      return (
        <>
          <div className="text-lg text-thin text-red-600 pt-5 pb-10">
            Once you burned your token, you cannot transfer it anymore.
          </div>
          <div className="flex justify-center w-full">
            <div className="w-3/4">
              <PrimaryButton label="BURN TOKEN" onClick={onClickBurn} />
            </div>
          </div>
        </>
      );
  }
};

export default RedeemCardBody;
