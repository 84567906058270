import { providers } from "ethers";

export function getLibrary(provider: providers.ExternalProvider): providers.Web3Provider {
  return new providers.Web3Provider(provider);
}

export function shortenAddress(address: string, chars = 4): string {
  return `${address.substring(0, chars + 2)}...${address.substring(42 - chars)}`;
}

export const getDataUrl = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result.toString());
    };
    reader.onerror = (error) => {
      console.log(error);
      reject(error);
    };
  });
