import { useEffect, useState } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { FirestoreToken } from "../types/FirestoreToken";

type UseBurnDateResponse = [burnDate: Date | undefined, loading: boolean, error: Error | undefined];

const getBurnDateFromFirestore = async (tokenId: number): Promise<Date> => {
  const tokenDocReference = doc(getFirestore(), "tokens", tokenId.toString());
  const tokenDocSnapshot = await getDoc(tokenDocReference);
  if (tokenDocSnapshot.exists()) {
    const tokenData = tokenDocSnapshot.data() as FirestoreToken;
    const burnDate = tokenData.burnDate.toDate();
    return burnDate;
  }
  throw new Error("Burn date not found");
};

export default (tokenId: number): UseBurnDateResponse => {
  const [burnDate, setBurnDate] = useState<Date>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    const getBurnDate = async () => {
      try {
        const tokenBurnDate = await getBurnDateFromFirestore(tokenId);
        setBurnDate(tokenBurnDate);
      } catch (er) {
        setError(er as Error);
      } finally {
        setLoading(false);
      }
    };

    void getBurnDate();
  }, [tokenId, setBurnDate]);

  return [burnDate, loading, error];
};
