import {
  UserEmailFormFields,
  UserEmailFormInitialValues,
  UserEmailFormValues,
} from "../../../../types/UserEmailForm";

export const emailFormFields: UserEmailFormFields = {
  email: {
    name: "email",
    label: null,
    initialValue: "",
    placeholder: "Enter your email address",
  },
};

export const getUserEmailInitialValues = (): UserEmailFormInitialValues => {
  const initialValues = {};

  Object.values(emailFormFields).forEach((formField) => {
    initialValues[formField.name] = formField.initialValue;
  });

  return initialValues as UserEmailFormValues;
};
