import { useWeb3React } from "@web3-react/core";
import { useHistory } from "react-router-dom";
import { providers } from "ethers";
import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import useBanityNftContract from "../../hooks/useBanityNftContract";
import useConnectWallet from "../../hooks/useConnectWallet";
import useVoucherSaleStatus from "../../hooks/useVoucherSaleStatus";
import PendingConfirmations from "../modals/PendingConfirmations";
import { useModal } from "../modals/ModalContext";
import ProgressBar from "../ProgressBar";
import ProgressStatus from "../ProgressStatus";
import PrimaryButton from "../buttons/PrimaryButton";
import redeemVoucher from "./redeemVoucher";
import RedeemVoucherConfirmation from "./RedeemVoucherConfirmation";
import sendFee from "./sendFee";
import CardImg from "../../static/images/Banity-cards.png";
import reserveRandomVoucher from "../../api/reserveRandomVoucher";
import getRandomVoucher from "../../api/getRandomVoucher";
import { gtagReportConversion } from "../../gtag";

const PRESALE_TOTAL = 646;
const MIN_CONFIRMATIONS = process.env.REACT_APP_MIN_CONFIRMATIONS || 1;

type ColProps = {
  children: React.ReactNode;
};

const Col = ({ children }: ColProps): JSX.Element => (
  <div className="flex-auto xl:max-w-6xl py-5 lg:py-20 xl:py-40 px-4 md:px-8 lg:px-16">
    {children}
  </div>
);

const LazyMint = (): JSX.Element | null => {
  const history = useHistory();

  const [saleStatus, statusLoading, statusError] = useVoucherSaleStatus();
  const { triggerConnect } = useConnectWallet();
  const { library, account, active } = useWeb3React<providers.Web3Provider>();
  const contract = useBanityNftContract();

  const auth = getAuth();
  const [authUser, loadingUser] = useAuthState(auth);

  const { setLoadingModal, setErrorModal } = useModal();
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [redeemedTokenId, setRedeemedTokenId] = useState<number | null>();
  const [displayPendingConfirmations, setDisplayPendingConfirmations] = useState<boolean>(false);
  const [currentTxHash, setCurrentTxHash] = useState<string>();
  const [mintButtonClicked, setMintButtonClicked] = useState<boolean>();

  useEffect(() => {
    const continueHandleMint = async () => {
      try {
        const authToken = await authUser.getIdToken();
        if (!authToken) {
          throw new Error("Could not authenticate with server.");
        }
        await reserveRandomVoucher(authToken);

        setLoadingModal("Step 1: Please transfer the fee with Metamask.");
        const feeTxHash = await sendFee(account, library);
        setCurrentTxHash(feeTxHash);
        setLoadingModal(null);
        setDisplayPendingConfirmations(true);
        await library.waitForTransaction(feeTxHash, +MIN_CONFIRMATIONS);

        setLoadingModal("Step 2: Retrieving random Banity voucher.");
        const voucher = await getRandomVoucher(feeTxHash, authToken);

        setLoadingModal(
          "Step 3: You are ready to mint your Banity token. Please Confirm in Metamask.",
        );
        const redeemTxHash = await redeemVoucher(voucher, account, contract);
        setCurrentTxHash(redeemTxHash);
        setDisplayPendingConfirmations(true);
        setRedeemedTokenId(voucher.tokenId);
      } catch (err) {
        console.debug(err);
        setErrorMessage((err as Error).message);
      } finally {
        setLoadingModal(null);
      }
    };

    if (mintButtonClicked) {
      if (loadingUser || !authUser) {
        setLoadingModal(`Please sign in using Metamask account: ${account}`);
        return;
      }

      continueHandleMint()
        .then(() => setMintButtonClicked(false))
        .catch((err) => console.log(err));
    }
  }, [account, contract, library, mintButtonClicked, authUser, loadingUser, setLoadingModal]);

  const handleMint = async () => {
    gtagReportConversion();

    try {
      setLoadingModal("Initializing Metamask ...");
      if (!(account && active)) {
        await triggerConnect();
      }
      setMintButtonClicked(true);
    } catch (err) {
      console.debug(err);
    }
  };

  const onRedeemVoucherConfirmationClose = () => {
    setRedeemedTokenId(null);
    history.push("/gallery");
  };

  useEffect(() => {
    if (errorMessage) {
      setErrorModal(errorMessage, {
        txHash: currentTxHash,
        additionalMessage:
          "If you have already paid for your voucher, please contact us and provide the following transaction hash.",
      });
    }
  }, [currentTxHash, errorMessage, setErrorModal]);

  const renderModal = (): JSX.Element | null => {
    if (displayPendingConfirmations) {
      return (
        <PendingConfirmations
          txHash={currentTxHash}
          onConfirmed={() => setDisplayPendingConfirmations(false)}
        />
      );
    }

    if (redeemedTokenId) {
      return (
        <RedeemVoucherConfirmation
          tokenId={redeemedTokenId}
          txHash={currentTxHash}
          onClose={onRedeemVoucherConfirmationClose}
        />
      );
    }

    return null;
  };

  let total = 0;
  let completion = 0;
  let soldWithPresale = 0;

  if (!statusLoading && !statusError) {
    total = saleStatus?.total + PRESALE_TOTAL;
    soldWithPresale = saleStatus?.sold + PRESALE_TOTAL;
    completion = (soldWithPresale / total) * 100;
  }

  return (
    <>
      <div>
        <div className="lg:min-h-screen flex justify-center flex-col lg:flex-row px-4">
          <Col>
            <div className="md:min-w-96 max-w-lg lg:ml-auto">
              <h1 className="text-6xl font-black font-inter mb-4">Mint your Banity NFT</h1>
              <div className="text-xl">
                Banity attaches great importance to fair distribution. The purchased Banity will be
                revealed after the user has minted it.
              </div>

              <div className="space-y-2 mt-8">
                <ProgressBar completion={completion} />
                {statusLoading ? (
                  <span>Loading...</span>
                ) : (
                  <ProgressStatus progress={soldWithPresale} total={total} label="sold" />
                )}
              </div>

              <div className="text-xl text-gray-900 mt-8 mb-2">
                Price: <b>0.5 ETH</b>
              </div>
              <PrimaryButton label="MINT" onClick={handleMint} disabled={completion === 100} />
            </div>
          </Col>
          <Col>
            <img src={CardImg} alt="img of a banity card" />
          </Col>
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default LazyMint;
