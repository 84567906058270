import { useState } from "react";
import StatusTag from "./StatusTag";
import { FirestoreToken, RedeemStage } from "../../types/FirestoreToken";
import { shortenAddress } from "../../utils/utils";
import useBanityNftMetadata from "../../hooks/useBanityNftMetadata";
import RedeemManagementForm from "./form/RedeemManagementForm";
import checked from "../../static/images/checked.svg";
import PrimaryButton from "../buttons/PrimaryButton";
import deleteTokenData from "../../firestore/deleteTokenData";

const RedeemManagementElement = ({
  tokenId,
  redeemStage,
  owner,
  burnDate,
  encryptionPublicKey,
}: FirestoreToken): JSX.Element => {
  const [submitting, setSubmitting] = useState(false);

  const [tokenMetaData] = useBanityNftMetadata(tokenId, redeemStage);
  const date = burnDate.toDate().toLocaleString();

  const updateFirestore = async () => {
    setSubmitting(true);
    await deleteTokenData(tokenId.toString());
    setSubmitting(false);
  };

  return (
    <tr
      className={`border-b-2 ${
        redeemStage === RedeemStage.FINISHED ? "text-gray-400" : "text-gray-700"
      }`}
    >
      <td className="p-2">{tokenId}</td>
      <td className="p-2"> {!tokenMetaData ? "Loading..." : tokenMetaData?.publicAddress} </td>
      <td>
        <StatusTag redeemStage={redeemStage} />
      </td>
      <td className="p-2">{shortenAddress(owner)}</td>
      <td className="p-2 border-r-2">{date}</td>
      <td>
        {redeemStage === RedeemStage.BURNED && (
          <RedeemManagementForm tokenId={tokenId} encryptionPublicKey={encryptionPublicKey} />
        )}

        {redeemStage === RedeemStage.PRIVATE_KEY_INSERTED ||
        redeemStage === RedeemStage.REDEEMED ? (
          <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2 justify-between items-center">
            <span className="pl-4 py-2 w-36">
              &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679;
            </span>
            {redeemStage === RedeemStage.PRIVATE_KEY_INSERTED && (
              <div className="flex w-28 justify-center">
                <img className="w-10" src={checked} alt="redeem icon" />
              </div>
            )}

            {redeemStage === RedeemStage.REDEEMED && (
              <div className="w-max">
                <PrimaryButton label="delete" onClick={updateFirestore} submitting={submitting} />
              </div>
            )}
          </div>
        ) : null}
      </td>
    </tr>
  );
};

export default RedeemManagementElement;
