import { ChangeEvent, useEffect, useRef } from "react";
import LeftRightText from "../LeftRightText";
import upload from "../../static/images/upload.svg";
import { FileUploadState, MintTokenFormFieldProps } from "../../types/MintTokenForm";

const FileUpload = ({
  formField,
  fileUploadStateProps,
  setFileUploadStateProps,
  formik,
}: MintTokenFormFieldProps & FileUploadState): JSX.Element => {
  const uploadFieldRef = useRef<HTMLInputElement>();

  const handleFileUpload = (fileUploadEvent: ChangeEvent<HTMLInputElement>) => {
    const currentFiles: FileList | null = fileUploadEvent.target.files;
    if (currentFiles && currentFiles.length > 0) {
      setFileUploadStateProps({
        isFileUploaded: true,
        fileUploadLabel: currentFiles[0].name,
        file: currentFiles[0],
      });
      formik.setFieldValue(formField.name, currentFiles[0]);
    }
  };

  useEffect(() => {
    if (!fileUploadStateProps.isFileUploaded) {
      uploadFieldRef.current.value = "";
    }
  }, [fileUploadStateProps]);

  return (
    <>
      <div className="pt-8">
        <label className="font-bold" htmlFor={formField.name}>
          {formField.label}
        </label>
        <div className="overflow-hidden relative py-5">
          <button
            className={`flex items-center justify-center ${
              fileUploadStateProps.isFileUploaded ? "bg-blue-700 flex-col" : "bg-blue-500"
            } bg-opacity-50 hover:bg-indigo-dark font-bold py-5 w-full rounded-md`}
            type="button"
          >
            <div className="relative">
              {fileUploadStateProps.isFileUploaded ? (
                <img
                  className="w-full right-0 top-2"
                  src={URL.createObjectURL(fileUploadStateProps.file)}
                  alt="file upload icon"
                />
              ) : (
                <img className="w-5 mr-3" src={upload} alt="file upload icon" />
              )}
            </div>
            <h2
              className={`text-center ml-2 ${
                fileUploadStateProps.isFileUploaded ? "underline" : ""
              }`}
            >
              {fileUploadStateProps.fileUploadLabel}
            </h2>
          </button>
          <input
            className="cursor-pointer absolute block w-full border rounded-md opacity-0 top-5 p-2"
            id={formField.name}
            name={formField.name}
            type="file"
            onChange={handleFileUpload}
            ref={uploadFieldRef}
          />
          <LeftRightText leftText="Supported file formats: png, jpg" rightText="600 x 800 px" />
        </div>
        {formik.errors[formField.name] && formik.touched[formField.name] ? (
          <div className="font-bold text-red-400 pb-5 pt-1">{formik.errors[formField.name]}</div>
        ) : null}
      </div>
    </>
  );
};

export default FileUpload;
