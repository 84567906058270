import { useState } from "react";

type Props = {
  src: string;
  alt: string;
  placeholderHeightClass: string;
};

const ImgWithLoadingPlaceholder = ({ src, alt, placeholderHeightClass }: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <div className="px-4">
      {loading && <div className={`w-full bg-gray-100 ${placeholderHeightClass}`} />}
      <img
        className={`object-contain w-full ${loading ? "hidden" : ""} `}
        src={src}
        alt={alt}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};

export default ImgWithLoadingPlaceholder;
