import { useWeb3React } from "@web3-react/core";
import { providers } from "ethers";
import { createContext, useEffect, useState } from "react";
import useBanityNftContract from "../hooks/useBanityNftContract";

interface UserContextProviderProps {
  children?: React.ReactNode;
}

type UserContextProps = {
  isMinter: boolean;
  loading: boolean;
};

export const UserContext = createContext<UserContextProps>({
  isMinter: false,
  loading: true,
});
UserContext.displayName = "UserContext";

const UserContextProvider = ({ children }: UserContextProviderProps): JSX.Element | null => {
  const [isMinter, setIsMinter] = useState<boolean>(false);
  const [rolesLoading, setRolesLoading] = useState<boolean>(true);
  const { account } = useWeb3React<providers.Web3Provider>();
  const contract = useBanityNftContract();

  useEffect(() => {
    if (!(account && contract)) {
      return;
    }

    const checkUserRoles = async () => {
      try {
        const minterRoleResponse: Array<string> =
          (await contract.functions.MINTER_ROLE()) as Array<string>;
        const minterRole = minterRoleResponse[0];

        const hasMinterRoleResponse: Array<boolean> = (await contract.functions.hasRole(
          minterRole,
          account,
        )) as Array<boolean>;

        setIsMinter(hasMinterRoleResponse[0]);
      } catch (err) {
        console.log(err);
      } finally {
        setRolesLoading(false);
      }
    };

    void checkUserRoles();
  }, [account, contract]);

  return (
    <UserContext.Provider value={{ isMinter, loading: rolesLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
