import { BanityNft } from "../types/BanityNft";
import { getDataUrl } from "../utils/utils";

const METADATA_ENDPOINT =
  process.env.REACT_APP_METADATA_ENDPOINT ||
  "http://localhost:5001/ce-ban-banity/europe-west1/metaData";

type UploadMetadataResponse = {
  uid: string;
};

async function uploadMetaData(
  token: BanityNft,
  imageFile: File,
  authToken: string,
): Promise<string> {
  const imageDataUrl: string = await getDataUrl(imageFile);

  const metaData = {
    tokenId: +token.tokenId,
    name: token.name,
    imageDataURL: imageDataUrl,
    publicAddress: token.publicAddress,
    trait: token.trait,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(metaData),
  };

  const fetchUrl = `${METADATA_ENDPOINT}/upload`;
  const response = await fetch(fetchUrl, requestOptions);

  const metadataResponse = (await response.json()) as UploadMetadataResponse;

  if (response.status === 200 && metadataResponse.uid) {
    console.debug("Uploaded metadata to uid: ", metadataResponse.uid);
    return metadataResponse.uid;
  }

  console.error(`Could not upload metadata: ${JSON.stringify(metadataResponse)}`);
  return null;
}

export default uploadMetaData;
