import CancelButton from "../buttons/CancelButton";
import ModalContainer from "../layout/ModalContainer";

type ErrorModalProps = {
  errorMessage: string | null;
  onClose: () => void;
  txHash?: string;
  additionalText?: string;
  additionalUrl?: string;
};

const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const onClickUrl =
  (url: string): (() => void) =>
  () =>
    openInNewTab(url);

const ErrorModal = ({
  errorMessage,
  onClose,
  txHash,
  additionalText,
  additionalUrl,
}: ErrorModalProps): JSX.Element => (
  <ModalContainer>
    <div className="text-center px-10 max-w-md">
      <span className="text-lg font-bold pt-10">Something went wrong</span>
      <div>
        <p className="break-words text-lg pt-10">{errorMessage}</p>
        {additionalText && <p className="text-left break-words text-lg pt-10">{additionalText}</p>}
      </div>
      {additionalUrl && (
        <div className="pt-4 text-lg font-bold text-blue-700">
          <button type="button" onClick={onClickUrl(additionalUrl)}>
            {additionalUrl}
          </button>
        </div>
      )}
      {txHash && (
        <div className="text-left pt-5">
          <b>Transaction hash</b>
          <p title="Transaction hash" className=" break-words text-sm">
            {txHash}
          </p>
        </div>
      )}
    </div>
    <div className="w-full pt-5 px-10">
      <CancelButton label="Close" onClick={onClose} />
    </div>
  </ModalContainer>
);

export default ErrorModal;
