import LoadingSpinner from "../layout/LoadingSpinner";
import checked from "../../static/images/checked_white.svg";

type SubmitButtonProps = {
  label: string;
  disabled?: boolean;
  submitting?: boolean;
  submitted?: boolean;
};

const SubmitButton = ({
  label,
  disabled = false,
  submitting = false,
  submitted = false,
}: SubmitButtonProps): JSX.Element => {
  if (submitted) {
    return (
      <div className="flex w-full h-full rounded-full bg-blue-700 text-white outline-none pl-4 pr-4 items-center justify-center">
        <img className="w-8 h-10" src={checked} alt="checkmark" />
      </div>
    );
  }
  return (
    <button
      className="w-full h-full rounded-full bg-blue-700 text-white hover:bg-gray-800 font-black text-xl outline-none p-4 text-center justify-center transition duration-300"
      type="submit"
      disabled={disabled || submitting || submitted}
    >
      {submitting ? <LoadingSpinner size="xs" color="white" /> : label}
    </button>
  );
};
export default SubmitButton;
