type LeftRightTextProps = {
  leftText: string;
  rightText: string;
};

const LeftRightText = ({ leftText, rightText }: LeftRightTextProps): JSX.Element => (
  <div className="flex xxs:flex-row text-left text-sm pb-5">
    <span className="flex-grow text-left">{leftText}</span>
    <span className="text-right">{rightText}</span>
  </div>
);

export default LeftRightText;
