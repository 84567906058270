const ENDPOINT =
  process.env.REACT_APP_TOKEN_ENDPOINT || "http://localhost:5001/banity/europe-west1/tokens";

const setTokenData = async (
  tokenId: number,
  encryptionPublicKey: string,
  authToken: string,
): Promise<void> => {
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({
      tokenId,
      encryptionPublicKey,
    }),
  };
  const fetchUrl = `${ENDPOINT}/saveToken`;
  const response = await fetch(fetchUrl, config);
  if (response.status === 201) {
    return;
  }
  if (response.status === 401) {
    throw new Error("Unauthorized user");
  }
  throw new Error(`Internal Server Error: ${response.status}`);
};

export default setTokenData;
