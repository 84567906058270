import { getFirestore, doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useEffect, useState } from "react";
import { RedeemStage, FirestoreToken } from "../types/FirestoreToken";

type UseRedeemStageResponse = [
  redeemStage: RedeemStage,
  loading: boolean,
  error: Error | undefined,
];

const convertNumberToRedeemStage = (x: number): RedeemStage => {
  if (x in RedeemStage) {
    return x as RedeemStage;
  }
  throw new Error("Invalid RedeemStage");
};

export default (tokenId: number): UseRedeemStageResponse => {
  const [redeemStage, setRedeemStage] = useState<RedeemStage>();

  const tokenDocReference = doc(getFirestore(), "tokens", tokenId.toString());
  const [token, loading, error] = useDocumentData(tokenDocReference);

  useEffect(() => {
    if (loading || error) {
      return;
    }

    try {
      setRedeemStage(convertNumberToRedeemStage((token as FirestoreToken).redeemStage));
    } catch (er: unknown) {
      if (er instanceof Error && er.message === "Invalid RedeemStage") {
        setRedeemStage(RedeemStage.BURNED);
      } else {
        setRedeemStage(RedeemStage.INITIAL);
      }
    }
  }, [error, loading, token]);
  return [redeemStage, loading, error];
};
