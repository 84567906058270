import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import useBanityNftContract from "./useBanityNftContract";
import { RedeemStage } from "../types/FirestoreToken";

type OwnedToken = { tokenId: number; redeemStage: RedeemStage };
type OwnedTokens = Array<OwnedToken>;
type OwnedBanityNftsResult = [OwnedTokens | undefined, boolean, Error | undefined];

export default (ownerAddress: string | null | undefined): OwnedBanityNftsResult => {
  const contract = useBanityNftContract();
  const [ownedTokens, setOwnedTokens] = useState<OwnedTokens>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    setLoading(true);
    const fetchTokenIds = async () => {
      if (!(ownerAddress && contract)) {
        setLoading(false);
        return;
      }
      try {
        const balanceResult: Array<BigNumber> = (await contract.functions.balanceOf(
          ownerAddress,
        )) as Array<BigNumber>;
        const ownerBalance = balanceResult[0].toNumber();

        if (ownerBalance < 1) {
          setLoading(false);
          return;
        }

        const tokenIdResults = [];
        for (let i = 0; i < ownerBalance; i += 1) {
          tokenIdResults.push(contract.functions.tokenOfOwnerByIndex(ownerAddress, i));
        }
        const tokenIds = (await Promise.all(tokenIdResults)).map((result: Array<BigNumber>) =>
          result[0].toNumber(),
        );
        const tokenData = tokenIds.map((tokenId: number) => ({
          tokenId,
          redeemStage: RedeemStage.INITIAL,
        }));
        setOwnedTokens(tokenData);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    setLoading(false);
    void fetchTokenIds();
  }, [ownerAddress, contract]);

  return [ownedTokens, loading, error];
};
