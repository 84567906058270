import { Timestamp } from "firebase/firestore";

export enum RedeemStage {
  INITIAL,
  BURNED,
  PRIVATE_KEY_INSERTED,
  REDEEMED,
  FINISHED,
}

export type FirestoreToken = {
  tokenId: number;
  tokenUri: string;
  isBurned: boolean;
  redeemStage: RedeemStage;
  owner: string;
  burnDate: Timestamp;
  encryptionPublicKey?: string;
  encryptionData?: string;
  signature?: string;
  adminPublicAddress?: string;
};
