import { ethers } from "ethers";
import { FirestoreToken } from "../types/FirestoreToken";

function verifiySignature({
  adminPublicAddress,
  encryptionData,
  signature,
}: Partial<FirestoreToken>): boolean {
  const recoveredAddress = ethers.utils.verifyMessage(encryptionData, signature);

  if (recoveredAddress !== adminPublicAddress) {
    throw new Error("Could not verify message. Invalid signature. Please contact admin.");
  }
  return recoveredAddress === adminPublicAddress;
}

export default verifiySignature;
