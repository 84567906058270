import { ethers } from "ethers";

async function burnToken(
  tokenId: number,
  contract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const response = (await contract.functions.burn(tokenId)) as ethers.providers.TransactionResponse;
  return response;
}

export default burnToken;
