import { useWeb3React } from "@web3-react/core";
import { useContext } from "react";
import banityLogo from "../static/images/banity_logo.png";
import andampLogo from "../static/images/andamp_logo.png";
import { UserContext } from "./UserContextProvider";

const Footer = (): JSX.Element => {
  const { isMinter } = useContext(UserContext);
  const { account } = useWeb3React();
  return (
    <footer className="flex flex-col items-center bottom-0 w-full pt-14  bg-blue-700">
      <a href="https://banity.com">
        <img className="h-20 w-auto " src={banityLogo} alt="banity logo" />
      </a>
      <div className="flex flex-col space-y-5 md:flex-row md:space-x-10 md:space-y-0 items-center  text-center pt-10 pb-20 px-10 justify-around">
        {account && isMinter && (
          <a className="text-white font-bold " href="/mint">
            Mint token
          </a>
        )}
        {account && (
          <a className="text-white font-bold " href="/gallery">
            Gallery
          </a>
        )}
        <a className="text-white font-bold" href="https://banity.com/about/">
          About
        </a>
        <a className="text-white font-bold" href="https://banity.com/faqs/">
          FAQs
        </a>
        <a
          className="text-white font-bold break-normal"
          href="https://banity.com/terms-and-conditions/"
        >
          Terms and Conditions
        </a>
      </div>
      <div className="w-full bg-blue-900 py-5 px-6 text-white font-bold text-lg inline-flex justify-between">
        <span>Copyrights @ BANITY | 2021</span>
        <a href="https://andamp.io" className="inline-flex gap-1">
          <span className="leading-8">made by</span>
          <img className="h-8" src={andampLogo} alt="andamp logo" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
