import {
  TransferTokenFormFields,
  TransferTokenFormInitialValues,
} from "../../../types/TransferTokenForm";

export const transferTokenFormFields: TransferTokenFormFields = {
  recipientAddress: {
    name: "recipientAddress",
    label: "Recipient address",
    initialValue: "",
    placeholder: "0x...",
  },
};

export const getTransferTokenInitialValues = (): TransferTokenFormInitialValues => {
  const initialValues = {};

  Object.values(transferTokenFormFields).forEach((formField) => {
    initialValues[formField.name] = formField.initialValue;
  });

  return initialValues as TransferTokenFormInitialValues;
};
