import ModalContainer from "../layout/ModalContainer";
import checkmark from "../../static/images/checkmark.svg";
import CancelButton from "../buttons/CancelButton";

type TransferTokenConfirmationProps = {
  txHash: string;
  recipientAddress: string;
  onClose: () => void;
};

const TransferTokenConfirmation = ({
  txHash,
  recipientAddress,
  onClose,
}: TransferTokenConfirmationProps): JSX.Element => (
  <ModalContainer>
    <div className="px-40">
      <img className="w-32" src={checkmark} alt="checkmark icon" />
    </div>
    <div className="text-lg font-bold pt-10">Token was successfully transferred!</div>
    <div className="pt-10 px-10">
      <b>Transfered to Recipient Address</b>
      <p className="break-words text-sm">{recipientAddress}</p>
    </div>
    <div className="pt-5 px-10">
      <b>Transaction hash</b>
      <p className="break-words text-sm">{txHash}</p>
    </div>
    <div className="w-full pt-5 px-10">
      <CancelButton label="Close" onClick={onClose} />
    </div>
  </ModalContainer>
);

export default TransferTokenConfirmation;
