import { Field } from "formik";
import { Trait } from "../../types/BanityNft";
import { MintTokenFormFieldProps } from "../../types/MintTokenForm";

const SelectField = ({ formField, formik }: MintTokenFormFieldProps): JSX.Element => (
  <>
    <div className="py-2">
      <b>{formField.label}</b>
      <Field
        as="select"
        name={formField.name}
        id={formField.name}
        placeholder={formField.placeholder}
        className="border leading-tight rounded-md text-black w-full px-3 py-3 form-select"
      >
        {Object.keys(Trait).map((key) => (
          <option value={Trait[key] as Trait} key={key}>
            {Trait[key]}
          </option>
        ))}
      </Field>
      {formik.errors[formField.name] && formik.touched[formField.name] && (
        <div className="text-red-400">{formik.errors[formField.name]}</div>
      )}
    </div>
  </>
);

export default SelectField;
