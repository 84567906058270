import ReactDOM from "react-dom";
import { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { providers } from "ethers";

import setTokenRedeemed from "../../../api/setTokenRedeemed";
import getAuthTokenOrSignIn from "../../../api/getAuthTokenOrSignIn";

import ModalContainer from "../../layout/ModalContainer";
import CancelButton from "../../buttons/CancelButton";
import warningSign from "../../../static/images/warning_sign.svg";
import PrimaryButton from "../../buttons/PrimaryButton";

type ConfirmDeleteBtcKeyModalProps = {
  setIsVisible: (arg) => void;
  tokenId: number;
};

const ConfirmDeleteBtcKeyModal = ({
  setIsVisible,
  tokenId,
}: ConfirmDeleteBtcKeyModalProps): JSX.Element => {
  const onCancel = () => {
    setIsVisible(false);
  };
  const [submitting, setSubmitting] = useState(false);

  const { library } = useWeb3React<providers.Web3Provider>();
  const signer = library.getSigner();

  const onConfirm = async (): Promise<void> => {
    setSubmitting(true);
    const authToken = await getAuthTokenOrSignIn(signer);
    await setTokenRedeemed(tokenId, authToken);
    setSubmitting(false);
    setIsVisible(false);
  };

  return ReactDOM.createPortal(
    <ModalContainer>
      <div className="max-w-md">
        <div className="flex w-full justify-center">
          <img className="w-24" src={warningSign} alt="warning sign" />
        </div>
        <div className="pt-10 pb-10 px-10 break-words">
          After you confirm that you have stored your private key, we will delete it from all our
          systems. Are you sure to confirm this deletion?
        </div>
        <div className="flex gap-4 w-full px-3">
          <CancelButton label="Cancel" onClick={onCancel} />
          <PrimaryButton label="Confirm" onClick={onConfirm} submitting={submitting} />
        </div>
      </div>
    </ModalContainer>,
    document.body,
  );
};

export default ConfirmDeleteBtcKeyModal;
