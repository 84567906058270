import { InjectedConnector } from "@web3-react/injected-connector";

const injected = new InjectedConnector({
  // mainnet = 1
  // rinkeby = 4
  // local hardhat = 1337
  supportedChainIds: [1, 4, 1337],
});

export default injected;
