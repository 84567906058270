import useBanityNftMetadata from "../../hooks/useBanityNftMetadata";
import CancelButton from "../buttons/CancelButton";
import ImgWithLoadingPlaceholder from "../ImgWithLoadingPlaceholder";
import ModalContainer from "../layout/ModalContainer";

type RedeemVoucherConfirmationProps = {
  tokenId: number | undefined;
  txHash: string;
  onClose: () => void;
};

const RedeemVoucherConfirmation = ({
  tokenId,
  txHash,
  onClose,
}: RedeemVoucherConfirmationProps): JSX.Element => {
  const [tokenMetaData, loading] = useBanityNftMetadata(tokenId);
  if (!tokenId) {
    return null;
  }

  return (
    <ModalContainer>
      <div className="max-w-sm px-5">
        <div className="flex content-center ">
          <span className="font-bold text-lg w-full text-center">
            Congratulations! You minted a Banity Token!
          </span>
        </div>
        <div className="py-3">
          <ImgWithLoadingPlaceholder
            src={tokenMetaData?.image}
            alt="NFT displayed as a card showing a vanity address"
            placeholderHeightClass="h-48"
          />
        </div>
        <div>
          <div className="font-thin text-left pt-1">Public Address</div>
          <div title="Public Address" className="text-sm font-bold text-left break-all">
            {loading ? "loading..." : tokenMetaData?.publicAddress}
          </div>
        </div>
        <div>
          <div className="font-thin text-left pt-3">Transaction hash</div>
          <div title="Transaction hash" className="text-s text-left break-all">
            {txHash}
          </div>
        </div>
        <div className="w-full pt-5">
          <CancelButton label="Close" onClick={onClose} />
        </div>
      </div>
    </ModalContainer>
  );
};

export default RedeemVoucherConfirmation;
