import useBurnDate from "../../../hooks/useBurnDate";

type BurnDateProps = {
  tokenId: number;
};

const addDays = (date: Date, days: number): Date => {
  const copy = new Date(date.getTime());
  copy.setDate(date.getDate() + days);
  return copy;
};

const BurnDate = ({ tokenId }: BurnDateProps): JSX.Element => {
  const [burnDate, loading, error] = useBurnDate(tokenId);

  if (loading) {
    return <div className="text-center">Loading burn date...</div>;
  }

  if (error) {
    return <div className="flex">Could not load burn date, please try again later</div>;
  }

  const redeemDate = addDays(burnDate, 3);

  return (
    <div className="grid grid-cols-3 gap-2 border-2 rounded-md font-semibold text-blue-800 border-blue-800 p-5">
      <div>Burned:</div>
      <div className="col-span-2">{burnDate.toLocaleString()}</div>
      <div>Ready by:</div>
      <div className=" col-span-2">{redeemDate.toLocaleString()}</div>
    </div>
  );
};

export default BurnDate;
