import { useWeb3React } from "@web3-react/core";
import { providers } from "ethers";
import { useEffect, useState } from "react";
import ModalContainer from "../layout/ModalContainer";

const MIN_CONFIRMATIONS = process.env.REACT_APP_MIN_CONFIRMATIONS || 3;

type PendingConfirmationsProps = {
  txHash: string;
  onConfirmed: () => void;
};

const PendingConfirmations = ({ txHash, onConfirmed }: PendingConfirmationsProps): JSX.Element => {
  const { library } = useWeb3React<providers.Web3Provider>();
  const [confirmations, setConfirmations] = useState<number>(0);

  useEffect(() => {
    if (library) {
      library.on(txHash, (transaction) => {
        const transactionCasted = transaction as providers.TransactionReceipt;
        setConfirmations(transactionCasted.confirmations);

        if (transactionCasted.confirmations >= MIN_CONFIRMATIONS) {
          onConfirmed();
        }
      });
    }

    return () => {
      if (library) {
        library.off(txHash);
      }
    };
  }, [library, onConfirmed, txHash]);

  return (
    <ModalContainer>
      <div className="px-40">
        <div
          style={{ borderTopColor: "transparent", borderBottomColor: "transparent" }}
          className="animate-spin rounded-full h-32 w-32 ease-linear border-8 border-blue-700"
        />
      </div>
      <div className="text-lg font-bold pt-10">{`Waiting for blockchain confirmations (${confirmations}/${MIN_CONFIRMATIONS})`}</div>
      <div className="pt-5 px-10">
        <b>Transaction hash</b>
        <p className="break-words text-sm">{txHash}</p>
      </div>
    </ModalContainer>
  );
};

export default PendingConfirmations;
