type Props = {
  completion: number;
};

const ProgressBar = ({ completion }: Props): JSX.Element => {
  const style = {
    width: `${completion}%`,
  };

  return (
    <div className="w-full bg-gray-300 rounded-full h-5 dark:bg-gray-700">
      <div className="bg-blue-700 h-5 rounded-full" style={style} />
    </div>
  );
};

export default ProgressBar;
