import { useWeb3React } from "@web3-react/core";
import { useEffect, useRef, useState } from "react";
import injected from "../utils/connectors";

function useConnectWallet() {
  const { activate } = useWeb3React();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const componentMounted = useRef<boolean>(true);

  const triggerConnect = async () => {
    setLoading(true);
    if (!(window.ethereum && window.ethereum.isMetaMask)) {
      setError("Please install Metamask.");
      setLoading(false);
      return;
    }

    try {
      await activate(injected, undefined, true);
    } catch (err) {
      console.error(err);
    } finally {
      if (componentMounted.current) {
        setLoading(false);
      }
    }
  };

  useEffect(
    () => () => {
      componentMounted.current = false;
    },
    [],
  );

  return { triggerConnect, loading, error };
}

export default useConnectWallet;
