const ENDPOINT =
  process.env.REACT_APP_USER_ENDPOINT || "http://localhost:5001/banity/europe-west1/users";

const setUserEmail = async (email: string, authToken: string): Promise<void> => {
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({
      email,
    }),
  };
  const fetchUrl = `${ENDPOINT}/setEmail`;
  const response = await fetch(fetchUrl, config);
  if (response.status === 200) {
    return;
  }
  if (response.status === 401) {
    throw new Error("Unauthorized user");
  }
  throw new Error(`Internal Server Error: ${response.status}`);
};

export default setUserEmail;
