import { useWeb3React } from "@web3-react/core";
import { createContext, useEffect, useState } from "react";
import { providers } from "ethers";
import injected from "../../utils/connectors";

interface MetamaskProviderProps {
  children?: React.ReactNode;
}

type MetamaskProviderContextProps = {
  loading: boolean;
};

export const MetamaskProviderContext = createContext<MetamaskProviderContextProps>({
  loading: true,
});
MetamaskProviderContext.displayName = "MetamaskProviderContext";

const MetamaskProvider = ({ children }: MetamaskProviderProps): JSX.Element | null => {
  const { active, error, activate } = useWeb3React<providers.Web3Provider>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    injected
      .isAuthorized()
      .then((isAuthorized) => {
        if (isAuthorized && !active && !error) {
          return activate(injected);
        }
        return Promise.resolve();
      })
      .finally(() => setLoading(false));
  }, [activate, active, error]);

  useEffect(() => {
    const handleChainChanged = () => {
      window.location.reload();
    };
    window.ethereum?.on("chainChanged", handleChainChanged);

    return () => {
      if (window.ethereum.removeListener) {
        window.ethereum.removeListener("chainChanged", handleChainChanged);
      }
    };
  }, []);

  return (
    <MetamaskProviderContext.Provider value={{ loading }}>
      {children}
    </MetamaskProviderContext.Provider>
  );
};

export default MetamaskProvider;
