type OptionalProps = {
  size?: "xs" | "sm" | "xl";
  color?: "blue" | "white";
};

const allShapes = {
  xs: "h-8 w-8 border-4",
  sm: "h-10 w-10 border-4",
  xl: "h-32 w-32 border-8",
};

const allColors = {
  blue: "border-blue-700",
  white: "border-white",
};

const LoadingSpinner = ({ size = "xl", color = "blue" }: OptionalProps): JSX.Element => (
  <div
    style={{ borderTopColor: "transparent", borderBottomColor: "transparent" }}
    className={`animate-spin rounded-full ${allShapes[size]} ${allColors[color]} ease-linear`}
  />
);

export default LoadingSpinner;
