import GalleryAnchor from "./GalleryAnchor";
import { RedeemStage } from "../../types/FirestoreToken";

type GalleryElementButtonProps = {
  tokenId: number;
  redeemStage: RedeemStage;
};

const GalleryElementButton = ({ tokenId, redeemStage }: GalleryElementButtonProps): JSX.Element => {
  if (redeemStage === RedeemStage.INITIAL) {
    return (
      <div className="gap-3 flex pt-4">
        <GalleryAnchor label="REDEEM" href={`/redeem/${tokenId}`} />
        <GalleryAnchor label="TRANSFER" href={`/transfer/${tokenId}`} active={false} />
      </div>
    );
  }
  if (redeemStage === RedeemStage.REDEEMED) {
    return (
      <div className="gap-3 flex justify-center pt-4">
        <div className="text-2xl font-extrabold text-green-700 pt-1">REDEEMED</div>
      </div>
    );
  }
  return (
    <div className="gap-3 flex pt-4">
      <GalleryAnchor label="Continue Redeem Process" href={`/redeem/${tokenId}`} />
    </div>
  );
};

export default GalleryElementButton;
