import { providers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import GalleryElement from "./GalleryElement";
import GalleryLastElement from "./GalleryLastElement";
import LoadingSpinner from "../layout/LoadingSpinner";
import useOwnedAllTokens from "../../hooks/useOwnedAllTokens";

const Gallery = (): JSX.Element => {
  const { account } = useWeb3React<providers.Web3Provider>();
  const [tokens, loading] = useOwnedAllTokens(account);

  if (loading) {
    return (
      <div className="flex w-full justify-center mb-auto">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div role="list" className="flex flex-row flex-wrap gap-10 py-10 px-5 mb-auto">
        {tokens?.map((token) => (
          <GalleryElement
            tokenId={token.tokenId}
            redeemStage={token.redeemStage}
            key={token.tokenId}
          />
        ))}
        <GalleryLastElement />
      </div>
    </>
  );
};
export default Gallery;
