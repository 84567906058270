import { useContext } from "react";
import { Redirect } from "react-router-dom";
import { getFirestore, collection, query, where, orderBy, Timestamp } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { UserContext } from "../UserContextProvider";
import { RedeemStage } from "../../types/FirestoreToken";
import ProgressBar from "../ProgressBar";
import ProgressStatus from "../ProgressStatus";
import RedeemManagementElement from "./RedeemManagementElement";
import convertNumberToRedeemStage from "../../utils/convertNumberToRedeemStage";

const TOTAL = 1001;

const RedeemManagement = (): JSX.Element | null => {
  const { isMinter, loading } = useContext(UserContext);

  const tokensCollection = collection(getFirestore(), "tokens");
  const tokenQuery = query(
    tokensCollection,
    where("isBurned", "==", true),
    orderBy("burnDate", "desc"),
  );
  const [firestoreTokens, firestoreLoading, firestoreError] = useCollection(tokenQuery);

  let completion = 0;
  let redeemedTotal = 0;

  if (!firestoreLoading && !firestoreError) {
    const redeemed = firestoreTokens.docs.filter(
      (doc) => doc.data().redeemStage === RedeemStage.FINISHED,
    );
    redeemedTotal = redeemed.length;
    completion = (redeemedTotal / TOTAL) * 100;
  }

  if (!loading && !isMinter) {
    return <Redirect to="/" />;
  }

  if (firestoreError) {
    console.error(firestoreError);
  }

  return (
    <div className="p-20">
      <div className="pb-10 flex justify-between space-x-14">
        <h1 className="text-5xl font-bold text-blue-700">Redeem Management</h1>
        <div className="space-y-2 w-80">
          <ProgressBar completion={completion} />
          {firestoreLoading ? (
            <span>Loading...</span>
          ) : (
            <ProgressStatus progress={redeemedTotal} total={TOTAL} label="redeemed" />
          )}
        </div>
      </div>
      {firestoreLoading ? (
        <span className="text-2xl">Collection: Loading...</span>
      ) : (
        <div className="p-4 bg-white overflow-x-auto">
          <table className="w-full table-auto border-collapse text-left ">
            <thead className="text-2xl ">
              <tr className="border-b-2">
                <th className="p-2 font-normal">Token</th>
                <th className="p-2 font-normal">Public Address</th>
                <th className="p-2 font-normal">Status</th>
                <th className="p-2 font-normal">Redeemer</th>
                <th className="p-2 font-normal">Burn Date</th>
              </tr>
            </thead>
            {firestoreTokens && (
              <tbody>
                {firestoreTokens.docs.map((token) => (
                  <RedeemManagementElement
                    key={token.id}
                    tokenId={parseInt(token.id, 10)}
                    redeemStage={convertNumberToRedeemStage(token.data().redeemStage as number)}
                    owner={token.data().owner as string}
                    burnDate={token.data().burnDate as Timestamp}
                    tokenUri={token.data().tokenUri as string}
                    isBurned={token.data().isBurned as boolean}
                    encryptionPublicKey={token.data().encryptionPublicKey as string}
                  />
                ))}
              </tbody>
            )}
          </table>
        </div>
      )}
    </div>
  );
};

export default RedeemManagement;
