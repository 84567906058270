import { BanityNftVoucherSigned } from "../types/BanityNft";

const VOUCHER_ENDPOINT =
  process.env.REACT_APP_VOUCHER_ENDPOINT ||
  "http://localhost:5001/ce-ban-banity/europe-west1/vouchers";

async function uploadVoucher(voucher: BanityNftVoucherSigned, authToken: string): Promise<void> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(voucher),
  };

  const fetchUrl = `${VOUCHER_ENDPOINT}/upload`;
  const response = await fetch(fetchUrl, requestOptions);

  if (response.status === 201) {
    console.debug("Uploaded voucher successfully");
    return;
  }

  throw new Error(`Could not upload voucher: ${response.status}`);
}

export default uploadVoucher;
