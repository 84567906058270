import React from "react";
import PrimaryButton from "../../buttons/PrimaryButton";

type DeleteBtcKeyBtnProps = {
  onClick: () => void;
};

const DeleteBtcKeyBtn = ({ onClick }: DeleteBtcKeyBtnProps) => (
  <>
    <div className="pb-3">
      Once you confirm, we will delete the private key from all our systems.
    </div>
    <div className="text-lg font-bold text-red-600 pb-5 text-center">This cannot be undone. </div>
    <div className="flex justify-center w-full">
      <div className="w-3/4">
        <PrimaryButton label="I have stored my private key" onClick={onClick} />
      </div>
    </div>
  </>
);

export default DeleteBtcKeyBtn;
