type GalleryAnchorProps = {
  label: string;
  href: string;
  active?: boolean;
};

const GalleryAnchor = ({ label, href, active = true }: GalleryAnchorProps): JSX.Element => (
  <a
    href={href}
    className={`flex-grow w-full rounded-full font-black text-xl px-4 py-2 text-center shadow-xl border border-gray-100 hover:bg-gray-800 transition duration-300 ${
      active ? "bg-blue-700 text-white" : "bg-white text-blue-700 hover:text-white"
    }`}
  >
    {label}
  </a>
);

export default GalleryAnchor;
