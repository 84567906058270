import { ethers } from "ethers";
import * as Yup from "yup";

const validateEthereumAddress = (address: string) => {
  const VALID_ETH_ADDRESS: boolean = ethers.utils.isAddress(address);
  return VALID_ETH_ADDRESS;
};

const TransferTokenFormValidation = (): Yup.AnyObjectSchema =>
  Yup.object().shape({
    recipientAddress: Yup.mixed()
      .test("The provided address is no valid Ethereum address.", (value: string) =>
        validateEthereumAddress(value),
      )
      .required("Recipient address is required"),
  });

export default TransferTokenFormValidation;
