import { useWeb3React } from "@web3-react/core";
import { providers } from "ethers";
import { Form, Formik, FormikErrors, FormikHelpers } from "formik";
import { useState } from "react";
import useBanityNftContract from "../../../hooks/useBanityNftContract";
import { BanityNft, BanityNftVoucher, BanityNftVoucherSigned } from "../../../types/BanityNft";
import {
  FileUploadStateProps,
  MintTokenFormInitialValues,
  MintTokenFormValues,
} from "../../../types/MintTokenForm";
import SubmitButton from "../../buttons/SubmitButton";
import MainContainer from "../../layout/MainContainer";
import FileUpload from "../FileUpload";
import uploadMetaData from "../../../api/uploadMetadata";
import uploadVoucher from "../../../api/uploadVoucher";
import MintOption from "../../../types/MintOption";
import { getMintTokenInitialValues, mintTokenFormFields } from "./mintTokenFormFields";
import MintTokenFormValidation from "./mintTokenValidation";
import SelectField from "../SelectField";
import SelectionToggle from "../SelectionToggle";
import TextField from "../../form/TextField";
import mintToken from "./mintToken";
import signVoucher from "./signVoucher";
import getAuthTokenOrSignIn from "../../../api/getAuthTokenOrSignIn";
import { useModal } from "../../modals/ModalContext";

type MintTokenFormProps = {
  onSubmitSuccess: (mintOption: MintOption, token?: BanityNft) => void;
};

const MintTokenForm = ({ onSubmitSuccess }: MintTokenFormProps): JSX.Element => {
  const { tokenId, name, publicAddress, trait, ownerAddress, image } = mintTokenFormFields;

  const [mintOption, setMintOption] = useState<MintOption>(MintOption.MINT);
  const [isImageUploaded, setIsImageUploaded] = useState<FileUploadStateProps>({
    isFileUploaded: false,
    fileUploadLabel: "Upload image",
    file: null,
  });

  const { setLoadingModal } = useModal();
  const [errorMessage, setErrorMessage] = useState<string | null>();

  const contract = useBanityNftContract();
  const { library } = useWeb3React<providers.Web3Provider>();

  const resetFormAfterSubmit = ({
    setSubmitting,
    resetForm,
  }: FormikHelpers<MintTokenFormValues>) => {
    setSubmitting(false);
    resetForm();
    setIsImageUploaded({
      isFileUploaded: false,
      fileUploadLabel: "Upload Image",
      file: null,
    });
  };

  const handleSubmitMint = async (
    values: MintTokenFormValues,
    formikHelpers: FormikHelpers<MintTokenFormValues>,
  ) => {
    try {
      const token: BanityNft = {
        ...values,
      };
      setLoadingModal("Uploading Metadata");
      const signer = library.getSigner();
      const authToken = await getAuthTokenOrSignIn(signer);
      const metadataUri = await uploadMetaData(token, isImageUploaded.file, authToken);

      if (!metadataUri) {
        setErrorMessage("Could not upload metadata");
        setLoadingModal(null);
        return;
      }

      token.tokenUri = metadataUri;
      setLoadingModal("Waiting for confirmation in Metamask");
      const mintedToken = await mintToken(token, contract);

      onSubmitSuccess(MintOption.MINT, mintedToken);
      resetFormAfterSubmit(formikHelpers);
    } catch (err) {
      setErrorMessage("Token could not be minted.");
    } finally {
      setLoadingModal(null);
    }
  };

  const handleSubmitLazyMint = async (
    values: MintTokenFormValues,
    formikHelpers: FormikHelpers<MintTokenFormValues>,
  ) => {
    try {
      const token: BanityNft = {
        ...values,
      };
      setLoadingModal("Uploading Metadata");
      const signer = library.getSigner();
      const authToken = await getAuthTokenOrSignIn(signer);
      const metadataUri = await uploadMetaData(token, isImageUploaded.file, authToken);

      if (!metadataUri) {
        setErrorMessage("Could not upload metadata");
        setLoadingModal(null);
        return;
      }

      const voucher: BanityNftVoucher = { tokenId: token.tokenId, tokenURI: metadataUri };
      setLoadingModal("Waiting for signature (Metamask)");
      const signature = await signVoucher(voucher, library, contract);

      const voucherSigned: BanityNftVoucherSigned = { ...voucher, signature };
      await uploadVoucher(voucherSigned, authToken);

      onSubmitSuccess(MintOption.LAZY_MINT);
      resetFormAfterSubmit(formikHelpers);
    } catch (err) {
      setErrorMessage("Token could not be minted.");
    } finally {
      setLoadingModal(null);
    }
  };

  const onToggleMintOption = (
    option: number,
    setErrors: (errors: FormikErrors<MintTokenFormInitialValues>) => void,
  ) => {
    if (option === 0) {
      setMintOption(MintOption.MINT);
    } else {
      setMintOption(MintOption.LAZY_MINT);
    }

    setErrors({});
  };

  const handleSubmit = async (
    values: MintTokenFormValues,
    formikHelpers: FormikHelpers<MintTokenFormValues>,
  ) => {
    if (mintOption === MintOption.MINT) {
      await handleSubmitMint(values, formikHelpers);
    } else if (mintOption === MintOption.LAZY_MINT) {
      await handleSubmitLazyMint(values, formikHelpers);
    }
  };

  return (
    <>
      <MainContainer>
        <h2 className="text-xl font-bold pt-5">Mint new Banity Token</h2>
        <Formik
          initialValues={getMintTokenInitialValues()}
          onSubmit={handleSubmit}
          validationSchema={MintTokenFormValidation(mintOption)}
        >
          {(formik) => (
            <Form className="w-full px-5" id="form_minttoken">
              <SelectionToggle
                optionOne={MintOption.MINT}
                optionTwo={MintOption.LAZY_MINT}
                onToggleChange={(option) => onToggleMintOption(option, formik.setErrors)}
              />
              <FileUpload
                formField={image}
                fileUploadStateProps={isImageUploaded}
                setFileUploadStateProps={setIsImageUploaded}
                formik={formik}
              />
              <TextField formField={tokenId} formik={formik} />
              <TextField formField={name} formik={formik} />
              <TextField formField={publicAddress} formik={formik} />
              <SelectField formField={trait} formik={formik} />
              {mintOption === MintOption.MINT && (
                <TextField formField={ownerAddress} formik={formik} />
              )}

              <div className="pt-5">
                {errorMessage && <p>{errorMessage}</p>}
                <SubmitButton label={mintOption} />
              </div>
            </Form>
          )}
        </Formik>
      </MainContainer>
    </>
  );
};

export default MintTokenForm;
