import { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { BanityNft } from "../../types/BanityNft";
import { UserContext } from "../UserContextProvider";
import LazyMintConfirmation from "./LazyMintConfirmation";
import MintOption from "../../types/MintOption";
import MintTokenConfirmation from "./MintTokenConfirmation";
import MintTokenForm from "./form/MintTokenForm";
import PendingConfirmations from "../modals/PendingConfirmations";

const Mint = (): JSX.Element | null => {
  const [activeStep, setActiveStep] = useState(0);
  const [mintOption, setMintOption] = useState<MintOption>(MintOption.MINT);

  const [token, setToken] = useState<BanityNft>();

  const { isMinter, loading } = useContext(UserContext);

  const displayNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const resetActiveStep = () => {
    setActiveStep(0);
  };

  const onSubmitSuccess = (option: MintOption, mintedToken?: BanityNft) => {
    setMintOption(option);
    setToken(mintedToken);

    if (option === MintOption.MINT) {
      displayNextStep();
    } else if (option === MintOption.LAZY_MINT) {
      setActiveStep(2);
    }
  };

  const renderModal = () => {
    switch (activeStep) {
      case 0:
        return null;
      case 1:
        return <PendingConfirmations txHash={token.mintTxHash} onConfirmed={displayNextStep} />;
      case 2:
        if (mintOption === MintOption.MINT) {
          return <MintTokenConfirmation token={token} onClose={resetActiveStep} />;
        }
        if (mintOption === MintOption.LAZY_MINT) {
          return <LazyMintConfirmation onClose={resetActiveStep} />;
        }
        return null;
      default:
        return null;
    }
  };

  if (!loading && !isMinter) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <MintTokenForm onSubmitSuccess={onSubmitSuccess} />
      {renderModal()}
    </>
  );
};

export default Mint;
