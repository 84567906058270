import { getFirestore, connectFirestoreEmulator, initializeFirestore } from "firebase/firestore";
import { initializeApp, getApps } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

if (!getApps().length) {
  const app = initializeApp(firebaseConfig);

  if (process.env.REACT_APP_USE_FIREBASE_EMULATORS === "true") {
    const auth = getAuth();
    initializeFirestore(app, { experimentalForceLongPolling: true });
    const db = getFirestore();
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(db, "localhost", 8080);
  }
}
