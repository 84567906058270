import { useWeb3React } from "@web3-react/core";
import { providers } from "ethers";
import { getAuth, signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import signInWithMetamask from "../utils/signInWithMetamask";
import { useModal } from "./modals/ModalContext";

interface FirebaseAuthHandlerProps {
  children?: React.ReactNode;
}

const FirebaseAuthHandler = ({ children }: FirebaseAuthHandlerProps): JSX.Element => {
  const { account, library } = useWeb3React<providers.Web3Provider>();

  const auth = getAuth();
  const [authUser, loadingUser, errorUser] = useAuthState(auth);

  const [signInInProgress, setSignInInProgress] = useState<boolean>(false);
  const [userDeniedSignIn, setUserDeniedSignIn] = useState<boolean>(false);
  const { setLoadingModal, setErrorModal } = useModal();

  useEffect(() => {
    if (loadingUser || errorUser || !auth || signInInProgress) {
      return;
    }

    async function signInToFirebase() {
      setLoadingModal(`Please sign in using Metamask account: ${account}`);
      const signer = library.getSigner();
      try {
        await signInWithMetamask(signer);
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (err?.code === 4001) {
          setUserDeniedSignIn(true);
        } else {
          throw err;
        }
      }
      setLoadingModal(null);
    }

    async function signOutOfFirebase() {
      await signOut(auth);
    }

    const accountConnectedButNotSignedIn = account && !authUser;
    if (accountConnectedButNotSignedIn) {
      if (!userDeniedSignIn) {
        setSignInInProgress(true);
        signInToFirebase().finally(() => setSignInInProgress(false));
      }

      return;
    }

    const accountConnectedButWrongUserSignedIn = account && authUser?.uid !== account;
    if (accountConnectedButWrongUserSignedIn) {
      setSignInInProgress(true);
      signOutOfFirebase().finally(() => {
        if (!userDeniedSignIn) {
          signInToFirebase()
            .catch((error) => console.error(error))
            .finally(() => setSignInInProgress(false));
        } else {
          setSignInInProgress(false);
        }
      });
    }

    const noAccountConnectedButUserSignedIn = !account && authUser;
    if (noAccountConnectedButUserSignedIn) {
      setSignInInProgress(true);
      signOutOfFirebase().finally(() => setSignInInProgress(false));
    }
  }, [
    authUser,
    account,
    loadingUser,
    auth,
    library,
    signInInProgress,
    userDeniedSignIn,
    errorUser,
    setLoadingModal,
  ]);

  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (userDeniedSignIn) {
      setErrorModal("You must sign in to use the app. Refresh the page to try again.", {
        onClose: refreshPage,
      });
    }
  }, [setErrorModal, userDeniedSignIn]);

  return <>{children}</>;
};

export default FirebaseAuthHandler;
