import { ethers } from "ethers";

async function transferToken(
  tokenId: number,
  from: string,
  to: string,
  contract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const response = (await contract["safeTransferFrom(address,address,uint256)"](
    from,
    to,
    tokenId,
  )) as ethers.providers.TransactionResponse;

  return response;
}

export default transferToken;
