import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import useConnectWallet from "../../hooks/useConnectWallet";
import { shortenAddress } from "../../utils/utils";
import { useModal } from "../modals/ModalContext";

type ConnectWalletInnerProps = {
  children: React.ReactNode;
};

const ConnectWalletInner = ({ children }: ConnectWalletInnerProps): JSX.Element => {
  const { account } = useWeb3React();
  const { triggerConnect, loading, error } = useConnectWallet();

  const { setErrorModal } = useModal();

  useEffect(() => {
    const refreshPage = () => {
      window.location.reload();
    };

    if (error === "Please install Metamask.") {
      const errorMessage = "To use the Banity dApp you need to install Metamask.";
      const additionalUrl = "https://metamask.io/";
      setErrorModal(errorMessage, {
        additionalUrl,
        onClose: refreshPage,
      });
    }
  }, [error, setErrorModal]);

  if (loading) {
    return <span data-cy="metamaskButton">Initializing...</span>;
  }

  if (error) {
    return <span data-cy="metamaskButton">Error: Could not connect.</span>;
  }

  if (account) {
    return <span data-cy="metamaskButton">{shortenAddress(account)}</span>;
  }

  return (
    <button
      className="w-full h-full"
      type="button"
      onClick={triggerConnect}
      data-cy="metamaskButton"
    >
      {children}
    </button>
  );
};

type ConnectMetamaskButtonProps = {
  children: React.ReactNode;
  className?: string;
};
const ConnectWalletButton = ({ className, children }: ConnectMetamaskButtonProps): JSX.Element => (
  <div
    className={
      className ||
      "rounded-md bg-blue-700 text-white font-bold outline-none p-3 w-64 h-14 flex items-center"
    }
  >
    <ConnectWalletInner>{children}</ConnectWalletInner>
  </div>
);

export default ConnectWalletButton;
