import { ethers } from "ethers";
import { BanityNftVoucher } from "../../../types/BanityNft";

async function signVoucher(
  voucher: BanityNftVoucher,
  library: ethers.providers.Web3Provider,
  contract: ethers.Contract,
): Promise<string> {
  const { chainId } = await library.getNetwork();
  const signer = library.getSigner();

  const domain = {
    name: "Banity",
    version: "1.0.0",
    chainId,
    verifyingContract: contract.address,
  };
  const types = {
    NFTVoucher: [
      { name: "tokenId", type: "uint256" },
      { name: "tokenURI", type: "string" },
    ],
  };

  // eslint-disable-next-line no-underscore-dangle
  const signature = await signer._signTypedData(domain, types, voucher);

  return signature;
}

export default signVoucher;
