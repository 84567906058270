import { useContext } from "react";
import { UserContext } from "../UserContextProvider";
import GalleryAnchor from "./GalleryAnchor";
import mintNewToken from "../../static/images/mintNewToken.png";

const GalleryLastElement = (): JSX.Element => {
  const { isMinter } = useContext(UserContext);

  const href = isMinter ? "/mint" : "/";

  return (
    <div className="container max-w-xs bg-white -solid px-3 py-3 flex flex-col pt-1">
      <a href={href} className="flex flex-grow py-8 pt-3 pb-0 justify-center">
        <div>
          <img src={mintNewToken} alt="banity card" />
        </div>
      </a>
      <div className="flex  pb-1">
        <GalleryAnchor label="Mint new token" href={href} />
      </div>
    </div>
  );
};

export default GalleryLastElement;
