import { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";

import { providers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import getAuthTokenOrSignIn from "../../../../api/getAuthTokenOrSignIn";

import TextField from "../../../form/TextField";

import { UserEmailFormValues } from "../../../../types/UserEmailForm";
import { getUserEmailInitialValues, emailFormFields } from "./userEmailFormFields";

import SubmitButton from "../../../buttons/SubmitButton";
import setUserEmail from "../../../../api/setUserEmail";
import userEmailValidation from "./userEmailValidation";

const UserEmailForm = (): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { library } = useWeb3React<providers.Web3Provider>();
  const { email } = emailFormFields;

  const handleSubmit = async (
    values: UserEmailFormValues,
    formikHelpers: FormikHelpers<UserEmailFormValues>,
  ): Promise<void> => {
    const signer = library.getSigner();
    const authToken = await getAuthTokenOrSignIn(signer);

    try {
      setSubmitting(true);
      await setUserEmail(values.email, authToken);
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm();
      setSubmitting(false);
      setSubmitted(true);
    } catch (err) {
      setErrorMessage(err as string);
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={getUserEmailInitialValues()}
      validationSchema={userEmailValidation}
    >
      {(formik) => (
        <Form>
          <div className="pt-5 px-5 py-5 flex justify-around gap-5">
            <div className="w-2/3">
              <TextField formField={email} formik={formik} />
            </div>
            <div className="w-1/3 justify-self-center">
              <SubmitButton label="Submit" submitting={submitting} submitted={submitted} />
            </div>
            {errorMessage && <p>{errorMessage}</p>}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserEmailForm;
