import { VoucherSaleStatus } from "../types/VoucherSaleStatus";

const VOUCHER_ENDPOINT =
  process.env.REACT_APP_VOUCHER_ENDPOINT ||
  "http://localhost:5001/ce-ban-banity/europe-west1/vouchers";

async function getVoucherSaleStatus(): Promise<VoucherSaleStatus> {
  const fetchUrl = `${VOUCHER_ENDPOINT}/saleStatus`;
  const response = await fetch(fetchUrl);

  if (response.status === 200) {
    return response.json() as Promise<VoucherSaleStatus>;
  }

  throw new Error(`Could not get voucher sale status: ${response.status}`);
}

export default getVoucherSaleStatus;
