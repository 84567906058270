import { Form, Formik } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import useBanityNftContract from "../../../hooks/useBanityNftContract";
import useBanityNftMetadata from "../../../hooks/useBanityNftMetadata";
import { TransferTokenFormValues } from "../../../types/TransferTokenForm";
import CancelButton from "../../buttons/CancelButton";
import SubmitButton from "../../buttons/SubmitButton";
import TextField from "../../form/TextField";
import ImgWithLoadingPlaceholder from "../../ImgWithLoadingPlaceholder";
import MainContainer from "../../layout/MainContainer";
import transferToken from "./transferToken";
import { getTransferTokenInitialValues, transferTokenFormFields } from "./transferTokenFormFields";
import TransferTokenFormValidation from "./transferTokenValidation";
import { useModal } from "../../modals/ModalContext";

type Props = {
  tokenId: number;
  account: string;
  onSubmitSuccess: (txHash: string, recipientAddress: string) => void;
};

const TransferTokenForm = ({ tokenId, account, onSubmitSuccess }: Props): JSX.Element => {
  const { recipientAddress } = transferTokenFormFields;

  const { setLoadingModal } = useModal();
  const [errorMessage, setErrorMessage] = useState<string>();

  const contract = useBanityNftContract();
  const [tokenMetaData, tokenMetaDataLoading] = useBanityNftMetadata(tokenId);
  const history = useHistory();

  const onCancel = () => {
    history.goBack();
  };

  const onSubmit = async (values: TransferTokenFormValues) => {
    try {
      setLoadingModal("Transferring Token: Waiting for confirmation in Metamask");
      const transferResponse = await transferToken(
        tokenId,
        account,
        values.recipientAddress,
        contract,
      );
      onSubmitSuccess(transferResponse.hash, values.recipientAddress);
    } catch (err) {
      setErrorMessage("Token could not be transferred.");
    } finally {
      setLoadingModal(null);
    }
  };

  return (
    <>
      <MainContainer>
        <h2 className="text-xl font-bold pt-5">Transfer your Banity Token</h2>

        <div className="w-full px-5 pt-8">
          <ImgWithLoadingPlaceholder
            src={tokenMetaData?.image}
            alt="NFT displayed as a credit card showing a vanity address"
            placeholderHeightClass="h-80"
          />
        </div>
        <div className="leading-tight text-black w-full px-5 py-5 pt-12">
          <div className="font-bold">Public Address</div>
          <div className="font-bold pt-2 break-all">
            {tokenMetaDataLoading ? "loading..." : tokenMetaData?.name}
          </div>
        </div>
        <Formik
          initialValues={getTransferTokenInitialValues()}
          onSubmit={onSubmit}
          validationSchema={TransferTokenFormValidation}
        >
          {(formik) => (
            <Form className="w-full px-5" id="form_transfertoken">
              <TextField formField={recipientAddress} formik={formik} />
              <div className="pt-5 flex gap-5">
                {errorMessage && <p>{errorMessage}</p>}
                <CancelButton label="Cancel" onClick={onCancel} />
                <SubmitButton label="Transfer token" />
              </div>
            </Form>
          )}
        </Formik>
      </MainContainer>
    </>
  );
};

export default TransferTokenForm;
