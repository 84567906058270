type BtcKeyContainerProps = {
  children: React.ReactNode;
};

type BtcKeyProps = {
  btcKey: string;
};

const BtcKeyContainer = ({ children }: BtcKeyContainerProps): JSX.Element => (
  <div className="pt-5 pb-8">
    <div className="flex w-full rounded-md p-3 bg-gray-200 ">{children}</div>
  </div>
);

const BtcKey = ({ btcKey }: BtcKeyProps): JSX.Element => {
  if (btcKey) {
    return (
      <BtcKeyContainer>
        <div className="w-4/5 text-lg p-4">{btcKey}</div>
        <button
          className="flex w-1/5 items-center justify-center text-center border text-gray-400 hover:text-gray-800"
          type="button"
          onClick={() => navigator.clipboard.writeText(btcKey)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
            />
          </svg>
        </button>
      </BtcKeyContainer>
    );
  }
  return (
    <BtcKeyContainer>
      <div className="w-5/6 tracking-widest text-lg p-4">
        · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · ·
        · · · · · · · · · · · · · · · · · · · · ·
      </div>
      <div className="flex w-1/6 items-center justify-center text-center border text-gray-400">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
          />
        </svg>
      </div>
    </BtcKeyContainer>
  );
};

export default BtcKey;
