import redeemActive from "../../static/images/redeem_active.svg";
import { RedeemStage } from "../../types/FirestoreToken";

type RedeemStageProps = {
  redeemStage: RedeemStage;
};

type RedeemStageTextProps = {
  isActive?: boolean;
  headline: string;
  description: string;
};

const allRedeemStageText = {
  burnToken: {
    headline: "Burn Token",
    description: "Banity will be notified that you burned your Token to start the redeem process.",
  },
  approval: {
    headline: "Approval",
    description: "Banity will manually approve the redeem. This can take up to 72 hours.",
  },
  redeem: {
    headline: "Redeem your key",
    description: "After the redeem has been approved, you can view your BTC key on this page.",
  },
};

const RedeemStageText = ({
  isActive,
  headline,
  description,
}: RedeemStageTextProps): JSX.Element => (
  <div className={isActive ? "text-blue-700" : "text-black"}>
    <div className="px-5">
      <div className="font-bold">{headline}</div>
      <div className="pt-2">{description}</div>
    </div>
  </div>
);

const CircleDeactivated = (): JSX.Element => (
  <div className="z-10">
    <div className="rounded-full bg-blue-700 w-10 h-10 flex-none" />
    <div className="rounded-full bg-white w-5 h-5 flex-none relative bottom-7.5 left-2.5" />
  </div>
);
const CircleDividerDeactivated = (): JSX.Element => (
  <>
    <CircleDeactivated />
    <div className="w-0.5 h-36 bg-blue-700 relative -left-5 top-5" />
  </>
);

const CircleActive = (): JSX.Element => (
  <div className="z-10">
    <div className="rounded-full bg-blue-700 w-10 h-10 flex-none 7-20" />
    <img
      className="w-5 flex-none relative bottom-7.5 left-2.5"
      src={redeemActive}
      alt="redeem icon"
    />
  </div>
);
const CircleDividerActive = (): JSX.Element => (
  <>
    <CircleActive />
    <div className="w-0.5 h-36 bg-blue-700 relative -left-5 top-5" />
  </>
);

const CircleInactive = (): JSX.Element => (
  <div className="rounded-full bg-white border-2 border-blue-700 z-10 w-10 h-10 flex-none" />
);
const CircleDividerInactive = (): JSX.Element => (
  <>
    <CircleInactive />
    <div className="w-0.5 h-36 bg-blue-700 relative -left-5 top-5" />
  </>
);

const RedeemStageBody = ({ redeemStage }: RedeemStageProps): JSX.Element => {
  switch (redeemStage) {
    case RedeemStage.INITIAL:
      return (
        <div className="text-left w-full mx-auto pl-10 max-w-xs sm:pt-12 pt-20 pb-10">
          <div className="flex w-full mx-auto">
            <CircleDividerActive />
            <RedeemStageText
              isActive
              headline={allRedeemStageText.burnToken.headline}
              description={allRedeemStageText.burnToken.description}
            />
          </div>

          <div className="flex w-full mx-auto">
            <CircleDividerInactive />
            <RedeemStageText
              headline={allRedeemStageText.approval.headline}
              description={allRedeemStageText.approval.description}
            />
          </div>

          <div className="flex w-full mx-auto">
            <CircleInactive />
            <RedeemStageText
              headline={allRedeemStageText.redeem.headline}
              description={allRedeemStageText.redeem.description}
            />
          </div>
        </div>
      );
    case RedeemStage.BURNED:
      return (
        <div className="text-left w-full mx-auto pl-10 max-w-xs sm:pt-5 pt-20 pb-10">
          <div className="flex w-full mx-auto">
            <CircleDividerDeactivated />
            <RedeemStageText
              headline={allRedeemStageText.burnToken.headline}
              description={allRedeemStageText.burnToken.description}
            />
          </div>

          <div className="flex w-full mx-auto">
            <CircleDividerActive />
            <RedeemStageText
              isActive
              headline={allRedeemStageText.approval.headline}
              description={allRedeemStageText.approval.description}
            />
          </div>

          <div className="flex w-full mx-auto">
            <CircleInactive />
            <RedeemStageText
              headline={allRedeemStageText.redeem.headline}
              description={allRedeemStageText.redeem.description}
            />
          </div>
        </div>
      );
    case RedeemStage.PRIVATE_KEY_INSERTED:
      return (
        <div className="text-left w-full mx-auto pl-10 max-w-xs sm:pt-5 pt-20 pb-10">
          <div className="flex w-full mx-auto">
            <CircleDividerDeactivated />
            <RedeemStageText
              headline={allRedeemStageText.burnToken.headline}
              description={allRedeemStageText.burnToken.description}
            />
          </div>

          <div className="flex w-full mx-auto">
            <CircleDividerDeactivated />
            <RedeemStageText
              headline={allRedeemStageText.approval.headline}
              description={allRedeemStageText.approval.description}
            />
          </div>

          <div className="flex w-full mx-auto">
            <CircleActive />
            <RedeemStageText
              isActive
              headline={allRedeemStageText.redeem.headline}
              description={allRedeemStageText.redeem.description}
            />
          </div>
        </div>
      );
    case RedeemStage.REDEEMED:
      return (
        <div className="text-left w-full mx-auto pl-10 max-w-xs sm:pt-5 pt-20 pb-10">
          <div className="flex w-full mx-auto">
            <CircleDividerDeactivated />
            <RedeemStageText
              headline={allRedeemStageText.burnToken.headline}
              description={allRedeemStageText.burnToken.description}
            />
          </div>

          <div className="flex w-full mx-auto">
            <CircleDividerDeactivated />
            <RedeemStageText
              headline={allRedeemStageText.approval.headline}
              description={allRedeemStageText.approval.description}
            />
          </div>

          <div className="flex w-full mx-auto">
            <CircleDeactivated />
            <RedeemStageText
              headline={allRedeemStageText.redeem.headline}
              description={allRedeemStageText.redeem.description}
            />
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default RedeemStageBody;
