import { BanityNftVoucherSigned } from "../types/BanityNft";

const VOUCHER_ENDPOINT =
  process.env.REACT_APP_VOUCHER_ENDPOINT ||
  "http://localhost:5001/ce-ban-banity/europe-west1/vouchers";

async function getRandomVoucher(
  txHash: string,
  authToken: string,
): Promise<BanityNftVoucherSigned> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({
      transactionHash: txHash,
    }),
  };

  const fetchUrl = `${VOUCHER_ENDPOINT}/getRandom`;
  const response = await fetch(fetchUrl, requestOptions);

  if (response.status === 200) {
    const voucher = (await response.json()) as BanityNftVoucherSigned;
    return voucher;
  }
  console.error(`Could not get random voucher: ${response.status}`);

  if (response.status === 404) {
    throw new Error("There are no Banity tokens left for sale.");
  }

  throw new Error(`Could not get random voucher.`);
}

export default getRandomVoucher;
