import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { Web3ReactProvider } from "@web3-react/core";
import { MetaMaskInpageProvider } from "@metamask/providers";
import MetamaskProvider from "./components/wallet/MetamaskProvider";
import Header from "./components/Header";
import { getLibrary } from "./utils/utils";
import Mint from "./components/mint/Mint";
import Gallery from "./components/gallery/Gallery";
import Transfer from "./components/transfer/Transfer";
import ProtectedRoute from "./ProtectedRoute";
import UserContextProvider from "./components/UserContextProvider";
import "./utils/firebase";
import Footer from "./components/Footer";
import LazyMint from "./components/lazymint/LazyMint";
import Redeem from "./components/redeem/Redeem";
import RedeemManagement from "./components/redeem-management/RedeemManagement";
import FirebaseAuthHandler from "./components/FirebaseAuthHandler";
import { ModalProvider } from "./components/modals/ModalContext";

declare global {
  interface Window {
    ethereum: MetaMaskInpageProvider;
  }
}

function App(): JSX.Element {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <MetamaskProvider>
        <UserContextProvider>
          <ModalProvider>
            <FirebaseAuthHandler>
              <div className="App flex flex-col justify-between bg-gray-100 min-h-screen">
                <Header />
                <Router>
                  <Switch>
                    <ProtectedRoute path="/gallery" component={Gallery} />
                    <ProtectedRoute exact path="/mint" component={Mint} />
                    <ProtectedRoute exact path="/redeem-management" component={RedeemManagement} />
                    <ProtectedRoute exact path="/redeem/:tokenId" component={Redeem} />
                    <ProtectedRoute exact path="/transfer/:tokenId" component={Transfer} />
                    <Route path="/" component={LazyMint} />
                  </Switch>
                </Router>
                <Footer />
              </div>
            </FirebaseAuthHandler>
          </ModalProvider>
        </UserContextProvider>
      </MetamaskProvider>
    </Web3ReactProvider>
  );
}

export default App;
