type ModalContainerProps = {
  children: React.ReactNode;
};

const ModalContainer = ({ children }: ModalContainerProps): JSX.Element => (
  <div className="flex fixed flex-col items-center bg-black bg-opacity-50 z-40 left-0 right-0 top-0 bottom-0">
    <div className="flex flex-col items-center justify-center overflow-hidden bg-white shadow-xl rounded-md z-50 mt-20 pt-10 pb-5 ">
      {children}
    </div>
  </div>
);

export default ModalContainer;
