import { useWeb3React } from "@web3-react/core";
import { providers, Contract } from "ethers";
import { useEffect, useState } from "react";
import * as Banity from "../abi/Banity.json";
import getContractAddress from "../utils/getContractAddress";

export default (): Contract => {
  const { library } = useWeb3React<providers.Web3Provider>();
  const [contract, setContract] = useState<Contract>();

  useEffect(() => {
    async function initContract() {
      const signer = library.getSigner();
      const chainId = await signer.getChainId();
      const contractAddress = getContractAddress(chainId);
      const banityContract = new Contract(contractAddress, Banity.abi, signer);
      setContract(banityContract);
    }
    if (library) {
      void initContract();
    }
  }, [library]);

  return contract;
};
