import { useEffect, useState } from "react";
import getVoucherSaleStatus from "../api/getVoucherSaleStatus";
import { VoucherSaleStatus } from "../types/VoucherSaleStatus";

function useVoucherSaleStatus(): [VoucherSaleStatus, boolean, Error | undefined] {
  const [saleStatus, setSaleStatus] = useState<VoucherSaleStatus>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    getVoucherSaleStatus()
      .then((status) => {
        setSaleStatus(status);
        setError(null);
      })
      .catch((err) => {
        console.error(err);
        setError(err as Error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [saleStatus, loading, error];
}

export default useVoucherSaleStatus;
