import { useWeb3React } from "@web3-react/core";
import { ErrorData } from "@firebase/util";
import { providers } from "ethers";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useOwnerOfToken from "../../hooks/useOwnerOfToken";
import useBanityNftContract from "../../hooks/useBanityNftContract";
import useRedeemStage from "../../hooks/useRedeemStage";

import PendingConfirmations from "../modals/PendingConfirmations";
import { useModal } from "../modals/ModalContext";

import RedeemCard from "./redeemCard/RedeemCard";
import burnToken from "./burnToken";
import RedeemStageBody from "./RedeemStageBody";
import LoadingSpinner from "../layout/LoadingSpinner";
import { RedeemStage } from "../../types/FirestoreToken";

import getAuthTokenOrSignIn from "../../api/getAuthTokenOrSignIn";
import setTokenData from "../../api/setTokenData";
import setTokenBurned from "../../api/setTokenBurned";
import getEncryptionPublicKey from "../../encrypt/getEncryptionPublicKey";

type Params = {
  tokenId: string;
};

const Redeem = (): JSX.Element => {
  const params = useParams<Params>();
  const tokenId = parseInt(params.tokenId, 10);
  const [owner, loading, error] = useOwnerOfToken(tokenId);
  const { account } = useWeb3React<providers.Web3Provider>();
  const contract = useBanityNftContract();

  const [txHash, setTxHash] = useState<string>();
  const [pendingConfirmation, setPendingConfirmation] = useState(false);
  const [redeemStage, loadingRedeemStage] = useRedeemStage(tokenId);

  const { setLoadingModal, setErrorModal } = useModal();
  const [errorMessage, setErrorMessage] = useState<string>();

  const { library } = useWeb3React<providers.Web3Provider>();

  const setRedeemStageApproval = () => {
    setErrorMessage(null);
    setPendingConfirmation(false);
  };

  const onPendingConfirmationConfirmed = async () => {
    try {
      const signer = library.getSigner();
      const authToken = await getAuthTokenOrSignIn(signer);
      await setTokenBurned(tokenId, authToken);
      setRedeemStageApproval();
    } catch (err) {
      setErrorMessage("We could not process the token burn. Please contact us by email.");
    }
  };

  const onClickBurn = async () => {
    setLoadingModal("Redeeming Token: Waiting for confirmation in Metamask");
    try {
      const signer = library.getSigner();
      const authToken = await getAuthTokenOrSignIn(signer);

      setLoadingModal("Request public key to securely send you messages");
      const encryptionPublicKey = await getEncryptionPublicKey(account);

      setLoadingModal("Burn token via Metamask");
      await setTokenData(tokenId, encryptionPublicKey, authToken);
      const burnResponse = await burnToken(tokenId, contract);

      setTxHash(burnResponse.hash);
      setPendingConfirmation(true);
    } catch (err) {
      const errorData = err as ErrorData;
      if (errorData.code === 4001) {
        setErrorMessage(
          "Please approve request of encryption public key and transaction signature.",
        );
      } else {
        setErrorMessage("There was an error during token burn. Please contact us by email.");
      }
    } finally {
      setLoadingModal(null);
    }
  };

  useEffect(() => {
    if (error || (!loading && !owner) || (!loading && owner !== account)) {
      setErrorModal("Could not verify ownership of token. Please try again later.", {
        txHash,
      });
    }
  }, [account, error, loading, owner, setErrorModal, txHash]);

  useEffect(() => {
    if (redeemStage === RedeemStage.FINISHED) {
      setErrorModal("The requested token is no longer available.");
    }
  }, [redeemStage, setErrorModal]);

  if (loading || loadingRedeemStage) {
    return (
      <div className="flex w-full justify-center ">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-wrap min-h-screen items-start bg-gray-200 break-words w-full sm:p-5 sm:flex-row">
        <RedeemStageBody redeemStage={redeemStage} />
        <RedeemCard
          redeemStage={redeemStage}
          onClickBurn={onClickBurn}
          errorMessage={errorMessage}
          tokenId={tokenId}
        />
      </div>
      {pendingConfirmation && (
        <PendingConfirmations txHash={txHash} onConfirmed={onPendingConfirmationConfirmed} />
      )}
    </>
  );
};

export default Redeem;
