import { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { providers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import updateTokenData from "../../../firestore/updateTokenData";
import encryptData from "../../../encrypt/encryptData";
import SubmitButton from "../../buttons/SubmitButton";
import TextField from "../../form/TextField";
import {
  getRedeemManagementInitialValues,
  redeemManagementFormFields,
} from "./redeemManagementFormFields";
import { RedeemManagementFormValues } from "../../../types/RedeemManagementForm";
import { Encryption } from "../../../types/Encryption";
import { FirestoreToken, RedeemStage } from "../../../types/FirestoreToken";

type RedeemManagementFormProps = {
  tokenId: number;
  encryptionPublicKey: string;
};

const resetFormAfterSubmit = ({
  setSubmitting,
  resetForm,
}: FormikHelpers<RedeemManagementFormValues>) => {
  setSubmitting(false);
  resetForm();
};

const RedeemManagementForm = ({
  tokenId,
  encryptionPublicKey,
}: RedeemManagementFormProps): JSX.Element => {
  const { privateKey } = redeemManagementFormFields;

  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState(false);

  const { library } = useWeb3React<providers.Web3Provider>();
  const signer = library.getSigner();

  const handleSubmit = async (
    values: RedeemManagementFormValues,
    formikHelpers: FormikHelpers<RedeemManagementFormValues>,
  ) => {
    setSubmitting(true);
    const encryption: Encryption = {
      encryptionPublicKey,
      data: values.privateKey,
    };
    const account = await signer.getAddress();

    try {
      const encryptionData = encryptData(encryption);
      const signature = await signer.signMessage(encryptionData);
      const updateToken: Partial<FirestoreToken> = {
        redeemStage: RedeemStage.PRIVATE_KEY_INSERTED,
        encryptionData,
        signature,
        adminPublicAddress: account,
      };

      await updateTokenData(tokenId.toString(), updateToken);
      setSubmitting(false);
    } catch (e) {
      const err = e as Error;
      setError(err.message);
      setSubmitting(false);
      resetFormAfterSubmit(formikHelpers);
    }
  };

  return (
    <Formik initialValues={getRedeemManagementInitialValues()} onSubmit={handleSubmit}>
      {(formik) => (
        <Form>
          <div className="pl-4 py-2 ">
            <div className="space-y-2 lg:space-y-0 lg:space-x-2 flex flex-col lg:flex-row justify-between items-center">
              <div className="xl:w-60 2xl:w-96">
                <TextField formField={privateKey} formik={formik} />
              </div>

              <div className="w-28 h-15">
                <SubmitButton label="encrypt" submitting={submitting} />
              </div>
            </div>
            {error && <span>{error}</span>}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RedeemManagementForm;
