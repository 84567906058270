import { RedeemStage } from "../../types/FirestoreToken";

const statusTagOptions = [
  { status: "default", color: "bg-gray-200" },
  { status: "requested", color: "bg-blue-50" },
  { status: "waiting", color: "bg-gray-200" },
  { status: "delete", color: "bg-blue-100" },
  { status: "finished", color: "bg-gray-200" },
];

type StatusTagProbs = {
  redeemStage: RedeemStage;
};

const StatusTag = ({ redeemStage }: StatusTagProbs): JSX.Element => (
  <>
    {redeemStage && (
      <div
        className={`w-20 text-center
                px-2 p-1 mx-2 my-4
                rounded ${statusTagOptions[redeemStage].color}`}
      >
        {statusTagOptions[redeemStage].status}
      </div>
    )}
  </>
);

export default StatusTag;
