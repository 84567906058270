import { useWeb3React } from "@web3-react/core";
import { providers } from "ethers";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useOwnerOfToken from "../../hooks/useOwnerOfToken";
import LoadingSpinner from "../layout/LoadingSpinner";
import PendingConfirmations from "../modals/PendingConfirmations";
import TransferTokenForm from "./form/TransferTokenForm";
import TransferTokenConfirmation from "./TransferTokenConfirmation";

type Params = {
  tokenId: string;
};

const Transfer = (): JSX.Element => {
  const params = useParams<Params>();
  const tokenId = parseInt(params.tokenId, 10);
  const [owner, loading, error] = useOwnerOfToken(tokenId);
  const history = useHistory();
  const { account } = useWeb3React<providers.Web3Provider>();

  const [txHash, setTxHash] = useState<string>();
  const [recipientAddress, setRecipientAddress] = useState<string>();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    function checkIfAccountIsOwnerOtherwhiseGoToHome() {
      if (!loading && !error && owner) {
        if (owner !== account) {
          history.push("/");
        }
      }
    }
    checkIfAccountIsOwnerOtherwhiseGoToHome();
  }, [owner, loading, error, history, account]);

  const displayNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const returnToGallery = () => {
    history.push("/gallery");
  };

  const onSubmitSuccess = (submittedTxHash: string, submittedRecipientAddress: string) => {
    setTxHash(submittedTxHash);
    setRecipientAddress(submittedRecipientAddress);
    displayNextStep();
  };

  const renderModal = () => {
    switch (activeStep) {
      case 0:
        return null;
      case 1:
        return <PendingConfirmations txHash={txHash} onConfirmed={displayNextStep} />;
      case 2:
        return (
          <TransferTokenConfirmation
            txHash={txHash}
            recipientAddress={recipientAddress}
            onClose={returnToGallery}
          />
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="flex w-full justify-center ">
        <LoadingSpinner />
      </div>
    );
  }

  if (error || !owner) {
    return <div>Could not verify ownership of token. Please try again later.</div>;
  }

  return (
    <>
      <TransferTokenForm tokenId={tokenId} onSubmitSuccess={onSubmitSuccess} account={account} />
      {renderModal()}
    </>
  );
};

export default Transfer;
