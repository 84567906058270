import ModalContainer from "../layout/ModalContainer";
import checkmark from "../../static/images/checkmark.svg";
import CancelButton from "../buttons/CancelButton";

type LazyMintConfirmationProps = {
  onClose: () => void;
};

const LazyMintConfirmation = ({ onClose }: LazyMintConfirmationProps): JSX.Element => (
  <ModalContainer>
    <div className="px-40">
      <img className="w-32" src={checkmark} alt="checkmark icon" />
    </div>
    <div className="text-lg font-bold pt-10">
      Token voucher was successfully stored and is ready to lazy mint!
    </div>
    <div className="w-full pt-5 px-10">
      <CancelButton label="Close" onClick={onClose} />
    </div>
  </ModalContainer>
);

export default LazyMintConfirmation;
