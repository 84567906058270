import {
  RedeemManagementFormFields,
  RedeemManagementFormInitialValues,
  RedeemManagementFormValues,
} from "../../../types/RedeemManagementForm";

export const redeemManagementFormFields: RedeemManagementFormFields = {
  privateKey: {
    name: "privateKey",
    label: "",
    initialValue: "",
    placeholder: "",
  },
};

export const getRedeemManagementInitialValues = (): RedeemManagementFormInitialValues => {
  const initialValues = {};

  Object.values(redeemManagementFormFields).forEach((formField) => {
    initialValues[formField.name] = formField.initialValue;
  });

  return initialValues as RedeemManagementFormValues;
};
