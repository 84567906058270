import useBanityNftMetadata from "../../hooks/useBanityNftMetadata";
import ImgWithLoadingPlaceholder from "../ImgWithLoadingPlaceholder";
import GalleryElementButton from "./GalleryElementButton";

import { RedeemStage } from "../../types/FirestoreToken";

type GalleryElementProps = {
  tokenId: number;
  redeemStage: RedeemStage;
};

const GalleryElement = ({ tokenId, redeemStage }: GalleryElementProps): JSX.Element => {
  const [tokenMetaData, loading] = useBanityNftMetadata(tokenId, redeemStage);

  return (
    <div role="listitem" className="flex flex-col max-w-xs bg-white border-solid rounded-lg p-5">
      <ImgWithLoadingPlaceholder
        src={tokenMetaData?.image}
        alt="NFT displayed as a credit card showing a vanity address"
        placeholderHeightClass="h-40"
      />
      <div className="pt-5">
        <div className="text-sm font-thin text-gray-600 text-left pt-1">Public Address:</div>
        <div className="text-sm font-thin text-left pb-1 break-all">
          {loading ? "loading..." : tokenMetaData?.publicAddress}
        </div>
      </div>
      <div className="mt-auto">
        <GalleryElementButton tokenId={tokenId} redeemStage={redeemStage} />
      </div>
    </div>
  );
};

export default GalleryElement;
