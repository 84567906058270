import { ethers } from "ethers";
import { getAuth } from "firebase/auth";
import signInWithMetamask from "../utils/signInWithMetamask";

async function getAuthTokenOrSignIn(signer: ethers.Signer): Promise<string> {
  try {
    const authToken = await getExistingAuthToken();

    if (authToken) {
      return authToken;
    }

    const newAuthToken = await signInWithMetamask(signer);
    return newAuthToken;
  } catch (err) {
    console.error(err);
    throw new Error("Could not authenticate with server.");
  }
}

async function getExistingAuthToken(): Promise<string | undefined> {
  return getAuth().currentUser?.getIdToken();
}

export default getAuthTokenOrSignIn;
