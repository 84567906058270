import { useEffect, useState } from "react";
import useOwnedBanityNfts from "./useOwnedBanityNfts";
import useOwnedBurnedNfts from "./useOwnedBurnedNfts";
import { RedeemStage } from "../types/FirestoreToken";

type OwnedToken = { tokenId: number; redeemStage: RedeemStage };
type OwnedTokens = Array<OwnedToken>;
type OwnedAllNftsResult = [OwnedTokens | undefined, boolean | undefined];

export default (ownerAddress: string | null | undefined): OwnedAllNftsResult => {
  const [ownedTokens, setOwnedTokens] = useState<OwnedTokens>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [ownedBanityTokens, loadingBanity] = useOwnedBanityNfts(ownerAddress);
  const [ownedBurnedTokens, loadingBurned] = useOwnedBurnedNfts(ownerAddress);

  useEffect(() => {
    const anyLoading = loadingBanity || loadingBurned;
    if (!anyLoading) {
      setLoading(true);
      const allTokens = [...ownedBanityTokens, ...ownedBurnedTokens];
      setOwnedTokens(allTokens);
      setLoading(false);
    }
  }, [loadingBanity, loadingBurned, ownedBanityTokens, ownedBurnedTokens]);

  return [ownedTokens, loading];
};
