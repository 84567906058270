import { ethers } from "ethers";
import { BanityNft } from "../../../types/BanityNft";

async function mintToken(token: BanityNft, contract: ethers.Contract): Promise<BanityNft> {
  const response = (await contract.functions.mint(
    token.ownerAddress,
    token.tokenId,
    token.tokenUri,
  )) as ethers.Transaction;

  const mintedToken: BanityNft = { ...token, mintTxHash: response.hash };
  return mintedToken;
}

export default mintToken;
