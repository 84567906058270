import { useState } from "react";

type Props = {
  optionOne: string;
  optionTwo: string;
  onToggleChange: (option: number) => void;
};

const SelectionToggle = ({ optionOne, optionTwo, onToggleChange }: Props): JSX.Element => {
  const [active, setActive] = useState<number>(0);

  const onOptionOneClick = () => {
    setActive(0);
    onToggleChange(0);
  };

  const onOptionTwoClick = () => {
    setActive(1);
    onToggleChange(1);
  };

  return (
    <div className="w-full my-2 rounded-md border">
      <button
        className={`text-center font-bold w-1/2 p-3 ${
          active === 0 ? "bg-blue-700 text-white" : ""
        }`}
        type="button"
        onClick={onOptionOneClick}
      >
        {optionOne}
      </button>
      <button
        className={`text-center font-bold w-1/2 p-3 ${
          active === 1 ? "bg-blue-700 text-white" : ""
        }`}
        type="button"
        onClick={onOptionTwoClick}
      >
        {optionTwo}
      </button>
    </div>
  );
};

export default SelectionToggle;
