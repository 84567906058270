import LoadingSpinner from "../layout/LoadingSpinner";

type PrimaryButtonProps = {
  label: string;
  disabled?: boolean;
  onClick: () => void;
  submitting?: boolean;
};

const PrimaryButton = ({
  label,
  onClick,
  submitting = false,
  disabled = false,
}: PrimaryButtonProps): JSX.Element => (
  <button
    className="flex w-full rounded-full bg-blue-700 text-white hover:bg-gray-800 font-black text-xl p-4 text-center items-center justify-center transition duration-300"
    type="button"
    onClick={onClick}
    disabled={submitting || disabled}
  >
    {submitting ? <LoadingSpinner size="xs" color="white" /> : label}
  </button>
);
export default PrimaryButton;
