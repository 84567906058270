import { Field, FormikProps } from "formik";
import { FormField } from "../../types/FormField";

type Props<FormValuesT> = {
  formField: FormField;
  formik: FormikProps<FormValuesT>;
};

const TextField = <FormValuesT,>({ formField, formik }: Props<FormValuesT>): JSX.Element => (
  <>
    <div className="py-2">
      {formField.label && <b>{formField.label}</b>}
      <Field
        name={formField.name}
        id={formField.name}
        type="text"
        className="border leading-tight rounded text-black w-full px-3 py-3"
        placeholder={formField.placeholder}
      />
      {formik.errors[formField.name] && formik.touched[formField.name] && (
        <div className="text-red-400">{formik.errors[formField.name]}</div>
      )}
    </div>
  </>
);
export default TextField;
