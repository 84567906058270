import React from "react";
import PrimaryButton from "../../buttons/PrimaryButton";

type RedeemBtcKeyBtnProps = {
  onClick: () => void;
};

const RedeemBtcKeyBtn = ({ onClick }: RedeemBtcKeyBtnProps) => (
  <div className="flex justify-center w-full">
    <div className="w-3/4">
      <PrimaryButton label="REDEEM VIA METAMASK" onClick={onClick} />
    </div>
  </div>
);

export default RedeemBtcKeyBtn;
