import { ethers, providers } from "ethers";

const BANITY_ACCOUNT_ADDRESS =
  process.env.REACT_APP_BANITY_ACCOUNT_ADDRESS || "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266";

const RANDOM_TOKEN_PRICE = process.env.REACT_APP_RANDOM_TOKEN_RRICE || 0.5;

async function sendFee(from: string, library: providers.Web3Provider): Promise<string> {
  try {
    const transactionParameters = {
      to: BANITY_ACCOUNT_ADDRESS,
      from,
      value: ethers.utils.parseEther(RANDOM_TOKEN_PRICE.toString()).toHexString(),
    };
    const paymentTransaction = (await library
      .getSigner()
      .sendTransaction(transactionParameters)) as ethers.Transaction;
    if (!paymentTransaction.hash) {
      throw new Error("Payment was not successful");
    }
    return paymentTransaction.hash;
  } catch (err) {
    throw new Error("Payment was not successful");
  }
}

export default sendFee;
