import { useEffect, useState } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import useBanityNftContract from "./useBanityNftContract";
import { FirestoreToken } from "../types/FirestoreToken";

export default (tokenId: number): [string, boolean, unknown] => {
  const contract = useBanityNftContract();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown>();
  const [owner, setOwner] = useState<string>();

  useEffect(() => {
    if (!contract) {
      return;
    }

    const setOwnerOfTokenFromFirestore = async (): Promise<void> => {
      const tokenDocReference = doc(getFirestore(), "tokens", tokenId.toString());
      const tokenDocSnapshot = await getDoc(tokenDocReference);

      if (tokenDocSnapshot.exists()) {
        const tokenData = tokenDocSnapshot.data() as FirestoreToken;
        setOwner(tokenData.owner);
        setError(undefined);
        setLoading(false);
        return;
      }
      throw new Error("Token could not be retrieved from firestore");
    };

    const setOwnerOfTokenFromContract = async (): Promise<void> => {
      const ownerResponse = (await contract.functions.ownerOf(tokenId)) as string[];
      setOwner(ownerResponse[0]);
      setLoading(false);
      setError(undefined);
    };

    const setOwnerOfToken = async (): Promise<void> => {
      try {
        await setOwnerOfTokenFromContract();
      } catch (contractError) {
        try {
          await setOwnerOfTokenFromFirestore();
        } catch (firestoreError) {
          setOwner(undefined);
          setLoading(false);
          setError(firestoreError);
        }
      }
    };

    void setOwnerOfToken();
  }, [tokenId, contract]);

  return [owner, loading, error];
};
