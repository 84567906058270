import { RedeemStage } from "../types/FirestoreToken";

const convertNumberToRedeemStage = (x: number): RedeemStage => {
  if (x in RedeemStage) {
    return x as RedeemStage;
  }
  return RedeemStage.FINISHED;
};

export default convertNumberToRedeemStage;
