export enum Trait {
  SILVER = "Silver",
  GOLD = "Gold",
  BLACK = "Black",
  RED = "Red",
  BLUE = "Blue",
}

export type BanityNft = {
  tokenId: number;
  name: string;
  publicAddress: string;
  trait: Trait;
  ownerAddress: string;
  tokenUri?: string;
  mintTxHash?: string;
};

export type BanityNftVoucher = {
  tokenId: number;
  tokenURI: string;
};

export type BanityNftVoucherSigned = BanityNftVoucher & {
  signature: string;
};
