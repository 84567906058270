type Props = {
  progress: number;
  total: number;
  label: string;
};

const ProgressStatus = ({ progress, total, label }: Props): JSX.Element => (
  <div>
    <span className="font-bold text-4xl text-blue-700">{progress}</span>
    <span className="mx-1 text-3xl text-gray-600">
      <sup>/ {total}</sup>
    </span>
    <span className="text-xl text-gray-600">{label}</span>
  </div>
);

export default ProgressStatus;
