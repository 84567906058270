import { useContext } from "react";
import { useWeb3React } from "@web3-react/core";
import ConnectWalletButton from "./wallet/ConnectWallet";
import banityLogo from "../static/images/banity_logo.png";
import { UserContext } from "./UserContextProvider";

const Header = (): JSX.Element => {
  const { isMinter } = useContext(UserContext);
  const { account } = useWeb3React();

  return (
    <div className="bg-blue-700 font-sans flex flex-col text-center sm:flex-row sm:justify-between py-4 px-6 shadow sm:items-center w-full">
      <div className="">
        <a href="https://banity.com">
          <img className="block h-10 w-auto" src={banityLogo} alt="banity logo" />
        </a>
      </div>
      <div className="flex items-center space-x-10">
        <a className="text-white" href="/">
          Mint random token
        </a>
        {account && isMinter && (
          <a className="text-white" href="/redeem-management">
            Redeem Management
          </a>
        )}
        {account && isMinter && (
          <a className="text-white text-bo" href="/mint">
            Mint token
          </a>
        )}
        {account && (
          <a className="text-white text-bo" href="/gallery">
            My Gallery
          </a>
        )}
        <ConnectWalletButton className="rounded-md bg-white w-36 shadow-sm outline-none p-2 text-center">
          Connect Wallet
        </ConnectWalletButton>
      </div>
    </div>
  );
};

export default Header;
