import { Contract, ethers } from "ethers";
import { BanityNftVoucherSigned } from "../../types/BanityNft";

async function redeemVoucher(
  voucher: BanityNftVoucherSigned,
  account: string,
  contract: Contract,
): Promise<string> {
  const redeemTransaction = (await contract.functions.redeem(
    account,
    voucher,
  )) as ethers.Transaction;
  if (!(redeemTransaction.hash && redeemTransaction.value)) {
    throw new Error("Could not redeem voucher");
  }
  return redeemTransaction.hash;
}

export default redeemVoucher;
