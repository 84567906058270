function getContractAddress(chainId: number) {
  switch (chainId) {
    case 1:
      return process.env.REACT_APP_CONTRACT_ADDRESS_MAINNET || "0xTODO";
    case 4:
      return (
        process.env.REACT_APP_CONTRACT_ADDRESS_RINKEBY ||
        "0x14a8d9F018530cf190c6d2C78262D8523CfDEB87"
      );
    case 1337:
      return (
        process.env.REACT_APP_CONTRACT_ADDRESS_LOCALHOST ||
        "0x5FbDB2315678afecb367f032d93F642f64180aa3"
      );
    default:
      throw new Error(`Unsupported chain: ${chainId}`);
  }
}

export default getContractAddress;
