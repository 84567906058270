const VOUCHER_ENDPOINT =
  process.env.REACT_APP_VOUCHER_ENDPOINT ||
  "http://localhost:5001/ce-ban-banity/europe-west1/vouchers";

async function reserveRandomVoucher(authToken: string): Promise<void> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  const fetchUrl = `${VOUCHER_ENDPOINT}/reserveRandom`;
  const response = await fetch(fetchUrl, requestOptions);

  if (response.status === 410) {
    console.error(`Could not reserve random voucher: ${response.status}`);
    throw new Error("There are no Banity tokens left for sale.");
  }

  if (response.status !== 200) {
    console.error(`Could not reserve random voucher: ${response.status}`);
    throw new Error(`Could not reserve random voucher.`);
  }
}

export default reserveRandomVoucher;
