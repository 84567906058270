type CancelButtonProps = {
  label: string;
  onClick: () => void;
};

const CancelButton = ({ label, onClick }: CancelButtonProps): JSX.Element => (
  <button
    className="w-full rounded-full bg-gray-500 text-white hover:bg-gray-800 font-black text-xl outline-none p-4 text-center transition duration-300"
    type="button"
    onClick={onClick}
  >
    {label}
  </button>
);

export default CancelButton;
