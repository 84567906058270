import React, { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import ErrorModal from "./ErrorModal";
import LoadingModal from "./LoadingModal";

type ModalState =
  | {
      state: "loading";
      loadingMessage: string;
    }
  | {
      state: "error";
      errorMessage: string;
      onClose: () => void;
      txHash?: string;
      additionalText?: string;
      additionalUrl?: string;
    }
  | null;

type ModalContextState = [ModalState, React.Dispatch<React.SetStateAction<ModalState>>];

const ModalContext = React.createContext<ModalContextState>(null);

type ModalRendererProps = React.PropsWithChildren<{
  modalState: ModalState;
}>;

const ModalRenderer = ({ modalState, children }: ModalRendererProps) => {
  if (modalState?.state === "error") {
    return (
      <ErrorModal
        errorMessage={modalState.errorMessage}
        onClose={modalState.onClose}
        txHash={modalState.txHash}
        additionalText={modalState.additionalText}
        additionalUrl={modalState.additionalUrl}
      />
    );
  }

  return (
    <>
      {modalState?.state === "loading" && (
        <LoadingModal loadingMessage={modalState.loadingMessage} />
      )}
      {children}
    </>
  );
};

type ModalProviderProps = {
  children?: React.ReactNode;
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const useModalState = useState<ModalState>(null);
  const [modalState] = useModalState;

  return (
    <ModalContext.Provider value={useModalState}>
      <ModalRenderer modalState={modalState}>{children}</ModalRenderer>
    </ModalContext.Provider>
  );
};

type ModalErrorOptions = {
  onClose?: () => void;
  txHash?: string;
  additionalMessage?: string;
  additionalUrl?: string;
};

type UseModal = {
  setLoadingModal: (message: string | null) => void;
  setErrorModal: (message?: string, options?: ModalErrorOptions) => void;
};

export function useModal(): UseModal {
  const [, setModalState] = useContext(ModalContext);

  const setLoadingModal = useCallback(
    (loadingMessage: string | null) => {
      if (loadingMessage) {
        setModalState({
          state: "loading",
          loadingMessage,
        });
      } else {
        // only clear loading state if prev state was loading
        setModalState((prevState) => (prevState?.state === "loading" ? null : prevState));
      }
    },
    [setModalState],
  );

  const history = useHistory();

  const setErrorModal = useCallback(
    (errorMessage?: string, options?: ModalErrorOptions) => {
      const handleClose =
        options?.onClose ??
        // by default go back to start
        (() => {
          history.push("/");
          setModalState(null);
        });
      setModalState({
        state: "error",
        errorMessage,
        onClose: handleClose,
        additionalText: options?.additionalMessage,
        txHash: options?.txHash,
        additionalUrl: options?.additionalUrl,
      });
    },
    [history, setModalState],
  );

  return { setLoadingModal, setErrorModal };
}
