import { getFirestore, doc, getDoc } from "firebase/firestore";

import { useEffect, useState } from "react";
import useBanityNftContract from "./useBanityNftContract";
import { RedeemStage, FirestoreToken } from "../types/FirestoreToken";

const IPFS_GATEWAY = process.env.REACT_APP_IPFS_GATEWAY || "https://ipfs.io/ipfs/";

export type BanityNftMetadata = {
  name: string;
  publicAddress: string;
  image: string;
  attributes: any[];
};

type UseBanityNftMetadataResponse = [BanityNftMetadata | undefined, boolean, Error | undefined];

const getTokenUriFromFirestore = async (tokenId: number): Promise<string> => {
  const tokenDocReference = doc(getFirestore(), "tokens", tokenId.toString());
  const tokenDocSnapshot = await getDoc(tokenDocReference);
  if (tokenDocSnapshot.exists()) {
    const tokenData = tokenDocSnapshot.data() as FirestoreToken;
    return tokenData.tokenUri;
  }
  throw new Error(`Token ${tokenId} not found in Firestore`);
};

export default (
  tokenId: number,
  redeemStage = RedeemStage.INITIAL,
): UseBanityNftMetadataResponse => {
  const contract = useBanityNftContract();
  const [tokenMetaData, setTokenMetaData] = useState<BanityNftMetadata | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    if (!(contract && tokenId)) {
      return;
    }
    const fetchMetaData = async () => {
      setLoading(true);
      try {
        let tokenUri: string;
        if (redeemStage === RedeemStage.INITIAL) {
          const tokenUriResult = (await contract.functions.tokenURI(tokenId)) as Array<string>;
          /* eslint-disable prefer-destructuring */
          tokenUri = tokenUriResult[0];
        } else {
          tokenUri = await getTokenUriFromFirestore(tokenId);
        }
        const gatewayUrl = tokenUri.replace("ipfs://", IPFS_GATEWAY);

        const ipfsResponse = await fetch(gatewayUrl);
        console.debug("Got metadata from ipfs for token with id ", tokenId);
        if (ipfsResponse && ipfsResponse.status === 200) {
          const metaDataResponse: BanityNftMetadata =
            (await ipfsResponse.json()) as BanityNftMetadata;
          metaDataResponse.image = metaDataResponse.image.replace("ipfs://", IPFS_GATEWAY);
          setTokenMetaData(metaDataResponse);
        } else {
          setError(new Error("Could not fetch token metadata."));
          return;
        }
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    void fetchMetaData();
  }, [tokenId, contract, redeemStage]);

  return [tokenMetaData, loading, error];
};
