import useBanityNftMetadata from "../../../hooks/useBanityNftMetadata";
import ImgWithLoadingPlaceholder from "../../ImgWithLoadingPlaceholder";
import RedeemCardBody from "./RedeemCardBody";
import { RedeemStage } from "../../../types/FirestoreToken";

type RedeemCardProps = {
  redeemStage: RedeemStage;
  onClickBurn: () => void;
  errorMessage: string;
  tokenId: number;
};

const RedeemCard = ({ redeemStage, onClickBurn, errorMessage, tokenId }: RedeemCardProps) => {
  const [tokenMetaData, tokenMetaDataLoading] = useBanityNftMetadata(tokenId, redeemStage);
  return (
    <div className="flex w-full lg:w-2/3 xl:w-2/3 justify-left">
      <div className="flex flex-col text-left items-center bg-white filter rounded-md drop-shadow-lg w-full mx-auto max-w-md md:max-w-xl pb-10">
        <div className="w-full px-20 pt-8">
          <ImgWithLoadingPlaceholder
            src={tokenMetaData?.image}
            alt="NFT displayed as a credit card showing a vanity address"
            placeholderHeightClass="h-80"
          />
        </div>
        <div className="leading-tight text-left text-black w-full px-10 py-5 pt-12 -mb-8">
          <div className="text-lg font-thin text-gray-600 ">Public Address:</div>
          <div className="text-lg font-thin pt-2 break-all">
            {tokenMetaDataLoading ? "loading..." : tokenMetaData?.publicAddress}
          </div>
          <RedeemCardBody redeemStage={redeemStage} onClickBurn={onClickBurn} tokenId={tokenId} />
          <div className="w-full pt-8">{errorMessage && <p>{errorMessage}</p>}</div>
        </div>
      </div>
    </div>
  );
};

export default RedeemCard;
