import ModalContainer from "../layout/ModalContainer";
import checkmark from "../../static/images/checkmark.svg";
import CancelButton from "../buttons/CancelButton";
import { BanityNft } from "../../types/BanityNft";

type MintTokenConfirmationProps = {
  token: BanityNft | undefined;
  onClose: () => void;
};

const MintTokenConfirmation = ({ token, onClose }: MintTokenConfirmationProps): JSX.Element => (
  <ModalContainer>
    <div className="px-40">
      <img className="w-32" src={checkmark} alt="checkmark icon" />
    </div>
    <div className="text-lg font-bold pt-10">Token was successfully minted!</div>
    <div className="pt-10 px-10">
      <b>Public Address</b>
      <p className="break-words text-sm">{token?.publicAddress}</p>
    </div>
    <div className="pt-5 px-10">
      <b>Transaction hash</b>
      <p className="break-words text-sm">{token?.mintTxHash}</p>
    </div>
    <div className="w-full pt-5 px-10">
      <CancelButton label="Close" onClick={onClose} />
    </div>
  </ModalContainer>
);

export default MintTokenConfirmation;
