type MainContainerProps = {
  children: React.ReactNode;
};
const MainContainer = ({ children }: MainContainerProps): JSX.Element => (
  <div className="flex flex-col min-h-screen content-between bg-gray-200 break-words w-full sm:p-5">
    <div className="flex flex-col text-left items-center bg-white filter rounded-md drop-shadow-lg w-full mx-auto max-w-md md:max-w-xl pb-10">
      {children}
    </div>
  </div>
);

export default MainContainer;
