import { doc, updateDoc, getFirestore, deleteField } from "firebase/firestore";
import { RedeemStage } from "../types/FirestoreToken";

async function deleteTokenData(id: string): Promise<void> {
  const docRef = doc(getFirestore(), "tokens", id);
  await updateDoc(docRef, {
    encryptionPublicKey: deleteField(),
    encryptionData: deleteField(),
    signature: deleteField(),
    adminPublicAddress: deleteField(),
    redeemStage: RedeemStage.FINISHED,
  });
}

export default deleteTokenData;
